<template>
  <div class="form" :class="{ 'c-expand': !keyboard }">
    <h1>Reset Password</h1>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="off"
          fill="outline"
          inputmode="email"
          label-placement="floating"
          label="Email"
          mode="md"
          type="email"
          :disabled="true"
          :value="email"
          @ionInput="email = $event.target.value"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="off"
          fill="outline"
          inputmode="text"
          label-placement="floating"
          label="Password"
          mode="md"
          type="password"
          :disabled="loading"
          :value="password"
          @ionInput="password = $event.target.value"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="off"
          fill="outline"
          inputmode="text"
          label-placement="floating"
          label="Confirm password"
          mode="md"
          type="password"
          :disabled="loading"
          :value="password1"
          @ionInput="password1 = $event.target.value"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <global-button
          expand="block"
          :disabled="disabled || loading"
          :loading="loading"
          text="Reset password"
          @click="submit"
        ></global-button>
      </ion-col>
    </ion-row>
  </div>
</template>

<script setup>
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth"

import { reactive, ref, toRefs, onMounted, watch } from "vue"
import { IonRow, IonCol, IonInput } from "@ionic/vue"
import App from "@/modules/app"
import Helpers from "@/modules/helpers"
import GlobalButton from "@/components/global/GlobalButton"

const props = defineProps({
  query: {
    type: String,
    default: null,
  },
  step: {
    type: String,
    default: null,
  },
  changeStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const { keyboard } = App()
const { openToast } = Helpers()
const form = reactive({
  email: "",
  password: "",
  password1: "",
})

const disabled = ref(true)
const loading = ref(false)
const error = ref(null)

onMounted(() => {
  verifyPasswordResetCode(getAuth(), props.query)
    .then((email) => {
      form.email = email
    })
    .catch((err) => {
      props.changeStep("login")
      openToast({
        color: "danger",
        message: "Invalid reset code",
      })
    })
})

watch(form, (updated) => {
  disabled.value = !(
    updated.email &&
    updated.password &&
    updated.password.length >= 6 &&
    updated.password1
  )
})

const { email, password, password1 } = toRefs(form)

const submit = async () => {
  loading.value = true
  error.value = null

  if (form.password != form.password1) {
    error.value = "Passwords do not match"
  } else if (form.password.length <= 6) {
    error.value = "Password must be greater than 6 characters"
  } else {
    await confirmPasswordReset(getAuth(), props.query, form.password)
      .then(async () => {
        openToast({
          color: "success",
          message: "Password reset",
        })
        props.changeStep("login")
      })
      .catch(() => {
        openToast({
          color: "danger",
          message: "Invalid reset code",
        })
        props.changeStep("login")
      })
  }

  if (error.value)
    openToast({
      color: "danger",
      message: error.value,
    })
  loading.value = false
}
</script>

<style lang="scss" scoped></style>
