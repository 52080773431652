<template>
  <div class="form" :class="{ 'c-expand': !keyboard }">
    <h1>Welcome back! 👋</h1>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="email"
          fill="outline"
          inputmode="email"
          label-placement="floating"
          label="Email"
          mode="md"
          type="email"
          :disabled="loading"
          :value="email"
          @ionInput="email = $event.target.value"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <div class="password">
          <ion-input
            autocomplete="off"
            fill="outline"
            inputmode="text"
            label-placement="floating"
            label="Password"
            mode="md"
            :type="showPassword ? 'text' : 'password'"
            :disabled="loading"
            :value="password"
            @ionInput="password = $event.target.value"
          ></ion-input>
          <ion-icon
            class="show"
            :icon="showPassword ? eyeOffOutline : eyeOutline"
            @click="showPassword = !showPassword"
          ></ion-icon>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col class="ion-text-right" size="12">
        <p class="light">
          <span class="link" @click="changeStep('forgot')"
            >Forgot password?</span
          >
        </p>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <global-button
          expand="block"
          :disabled="disabled || loading"
          :loading="loading"
          text="Login"
          @click="submit"
        ></global-button>
      </ion-col>
    </ion-row>
  </div>
  <ion-row class="ion-align-items-center c-modal-footer" v-if="!keyboard">
    <ion-col class="ion-text-center" size="12">
      <p class="light">
        Don't have an account?
        <span class="link" @click="changeStep('signup')">Sign up</span>
      </p>
    </ion-col>
  </ion-row>
</template>

<script setup>
import { reactive, ref, toRefs, watch } from "vue"
import { IonRow, IonCol, IonInput, IonIcon } from "@ionic/vue"
import { eyeOutline, eyeOffOutline } from "ionicons/icons"
import App from "@/modules/app"
import Helpers from "@/modules/helpers"
import User from "@/modules/user"

import GlobalButton from "@/components/global/GlobalButton"

const { keyboard } = App()
const { openModal, openToast, validEmail, clearModal } = Helpers()
const { login, loadingUser } = User()

const props = defineProps({
  nextStep: {
    type: Function,
    default: () => {},
  },
  changeStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const loading = ref(false)
const error = ref(null)
const disabled = ref(true)
const showPassword = ref(false)

const form = reactive({
  email: "",
  password: "",
})

watch(form, (updated) => {
  disabled.value = !(
    updated.email &&
    validEmail(updated.email) &&
    updated.email.length >= 5 &&
    updated.password &&
    updated.password.length >= 6
  )
})

const { email, password } = toRefs(form)

const submit = async () => {
  loading.value = true
  let res = await login(form)
  if (res.error) {
    loading.value = false
    return openToast({
      color: "danger",
      message: res.error,
    })
  }
  clearModal("ProfileModal")
  let tries = 0
  while (tries <= 10) {
    await new Promise((res) => setTimeout(res, 2500))
    if (!loadingUser.value) tries = 11
    else tries++
  }
  loading.value = false
  await props.dismissModal()
  await props.nextStep()
}
</script>
