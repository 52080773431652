import axios from "axios"

import { reactive, toRefs } from "vue"

import Helpers from "@/modules/helpers"
import User from "@/modules/user"

const state = reactive({})

export default function () {
  const api = async ({
    url,
    bypass = false,
    query = null,
    data = null,
    method = "GET",
    custom = false,
  }) => {
    const { headers } = Helpers()
    const { checkToken } = User()
    const controller = new AbortController()
    if (!bypass && !custom) await checkToken()
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.timeout = 30000
    if (custom) axios.defaults.timeout = 120000

    return axios({
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        ...(await headers(bypass)),
      },
      url: `${url}${query ? `?${query}` : ""}`,
      method,
      data,
    })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        if (err.message == "canceled") return { error: "canceled" }
        return err.response ? err.response.data : { error: "api error" }
      })
  }

  return {
    ...toRefs(state),
    api,
  }
}
