import _ from "lodash"
import { getMode } from "@nano-sql/adapter-sqlite-cordova"
import { nSQL } from "@nano-sql/core"
import moment from "moment"

import Schema from "@/modules/schema.json"

export default function () {
  const init = async () => {
    if (nSQL().dbs.the_clubhouse) return {}
    return nSQL()
      .connect({
        id: "the_clubhouse",
        mode: getMode(),
        tables: Schema,
      })
      .then((e) => e)
      .catch((err) => {})
  }

  const query = ({ table, where = [] }) => {
    return nSQL(table)
      .useDatabase("the_clubhouse")
      .query("select")
      .where(where)
      .exec()
      .then((result) => result)
      .catch((err) => {})
  }

  const dump = ({ table, data }) => {
    data = _.cloneDeep(data).map((a) => ({ id: a._id, ...a }))
    return nSQL(table)
      .loadJS(data)
      .then(() => {})
      .catch((err) => {})
  }

  const upsert = ({ table, data, where = null }) => {
    let updated = _.cloneDeep(data)
    updated.updated = moment().format()
    if (where)
      return nSQL(table)
        .query("upsert", updated)
        .where(where)
        .exec()
        .then(() => {})
        .catch((err) => {})
    return nSQL(table)
      .query("upsert", updated)
      .exec()
      .then(() => {})
      .catch((err) => {})
  }

  const deleteItem = ({ table, data }) => {
    return nSQL(table)
      .query("delete")
      .where(data)
      .exec()
      .then(() => {})
      .catch((err) => {})
  }

  const deleteAll = ({ table }) => {
    return nSQL(table)
      .query("delete")
      .exec()
      .then(() => {})
      .catch((err) => {})
  }

  return {
    init,
    dump,
    query,
    upsert,
    deleteItem,
    deleteAll,
  }
}
