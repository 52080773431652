import _ from "lodash"
import * as d3 from "d3"
import { reactive } from "vue"
import { Svg2Roughjs } from "svg2roughjs"

const turf = require("@turf/turf")

import App from "@/modules/app"
import Map from "@/modules/helpers/map"
import Scoring from "@/modules/tournament/scoring"
import Location from "@/modules/tournament/gps/location"

const defaultValues = {
  container: {},
  svg: null,
  projection: null,

  bearing: null,
  aim: null,
  green: null,
  lastMarked: null,
  aimPoint: null,
}

const state = reactive(_.cloneDeep(defaultValues))

export default function () {
  const init = async (container) => {
    state.container = {
      height: container.clientHeight,
      width: container.clientWidth,
      offsetLeft: container.offsetLeft,
      offsetTop: container.offsetTop,
    }
    goto()
  }

  const goto = async () => {
    const { coordinates, instant } = Location()
    const { holeVector, currentCourse } = Scoring()

    state.lastMarked = null

    let courseVectors = ["Water", "Tree", "Sand"]
    let vectors = ["Perimeter", "Fairway", "Teebox", "Green", "Bunker"]

    let polygons = []
    let otherPolygons = []

    let greenCenter = holeVector.value.Greencenter.Shapes.Shape[0].Points[0]
    let teeCenter = holeVector.value.Teeboxcenter.Shapes.Shape[0].Points[0]

    state.bearing = turf.bearing(turf.point(teeCenter), turf.point(greenCenter))
    state.green = greenCenter
    state.aim = null

    Object.entries(holeVector.value).map((holeData) => {
      if (vectors.includes(holeData[0])) {
        let points = holeData[1]
        if (points && points.Shapes && points.Shapes.Shape) {
          points.Shapes.Shape.map((shape, index) => {
            if (shape.Points.length) {
              shape.Points.push(shape.Points[0])
              if (holeData[0] == "Perimeter")
                otherPolygons.push(
                  turf.lineString(shape.Points, {
                    type: holeData[0],
                  })
                )
              polygons.push(
                turf.lineString(shape.Points, {
                  type: holeData[0],
                })
              )
            }
          })
        }
      } else if (holeData[0] == "Centralpath") {
        if (holeData[1].Shapes.Shape[0].Points.length == 3) {
          state.aim = holeData[1].Shapes.Shape[0].Points[1]
          state.aimPoint = holeData[1].Shapes.Shape[0].Points[1]
        }
      }
    })

    Object.entries(currentCourse.value.gpsVector).map((course) => {
      if (courseVectors.includes(course[0])) {
        let points = course[1]
        if (points && points.Shapes && points.Shapes.Shape) {
          points.Shapes.Shape.map((shape, index) => {
            if (shape.Points.length) {
              if (course[0] != "Tree") {
                shape.Points.push(shape.Points[0])
                otherPolygons.push(
                  turf.lineString(shape.Points, {
                    type: course[0],
                  })
                )
              }
            }
          })
        }
      }
    })

    let geojson = {
      type: "FeatureCollection",
      features: polygons,
    }

    state.projection = d3
      .geoMercator()
      .angle([state.bearing || 0])
      .fitSize([state.container.width, state.container.height], geojson)

    let geoGenerator = d3.geoPath().projection(state.projection)

    await d3.selectAll(`.course-map svg`).remove()
    state.svg = d3
      .select(`.course-map`)
      .append("svg")
      .attr("id", "svg")
      .attr("width", state.container.width)
      .attr("height", state.container.height)

    state.svg
      .append("g")
      .selectAll("path")
      .data(otherPolygons)
      .join("path")
      .attr("d", geoGenerator)
      .attr("class", (d) => `${d.properties.type.toLowerCase()} vector`)

    state.svg
      .append("g")
      .selectAll("path")
      .data(geojson.features)
      .join("path")
      .attr("d", geoGenerator)
      .attr("class", (d) => `${d.properties.type.toLowerCase()} vector hole`)

    d3.select(".course-map").on("touchstart", (d) => {
      instant()
      state.aim = state.projection.invert([
        d.touches[0].clientX,
        d.touches[0].clientY - state.container.offsetTop,
      ])
      state.lastMarked = coordinates.value
      updateLocation()
    })
    updateLocation()
  }

  const updateLocation = () => {
    const curve = d3.line().curve(d3.curveNatural)

    const { metadata } = App()
    const { distanceBetween, midpoint } = Map()
    const { coordinates } = Location()
    const { holeVector } = Scoring()

    if (state.svg) {
      let dots = [_.cloneDeep(coordinates.value), state.green]

      let intersection = false

      if (state.aim) {
        dots.splice(1, 0, state.aim)
        holeVector.value.Green.Shapes.Shape.map((shape) => {
          let points = shape.Points
          let greenLine = turf.lineString(points)
          let greenPolygon = turf.lineToPolygon(greenLine)
          if (turf.booleanIntersects(greenPolygon, turf.point(state.aim)))
            intersection = true
        })
      }

      if (state.aim == state.aimPoint) {
        let onTee = false
        holeVector.value.Teebox.Shapes.Shape.map((shape) => {
          let points = shape.Points
          let teeLine = turf.lineString(points)
          let teePolygon = turf.lineToPolygon(teeLine)
          if (turf.booleanIntersects(teePolygon, turf.point(coordinates.value)))
            onTee = true
        })

        if (!onTee) dots.splice(1, 1)
      }

      if (intersection) dots.splice(2, 1)

      state.svg.selectAll("circle").remove()
      state.svg.selectAll("text").remove()
      state.svg.selectAll(".text").remove()
      state.svg.selectAll(".lines").remove()

      if (state.lastMarked && dots.length > 2) {
        let fromLast = distanceBetween(state.lastMarked, coordinates.value)
        if (fromLast > 30) {
          dots.splice(1, 1)
          state.aim = null
        }
      }

      dots.map((dot, i) => {
        let prev = dots[i - 1]
        let xy = state.projection(dot)

        if (i > 0) {
          let distance = distanceBetween(prev, dot)
          let middle = midpoint(prev, dot)
          let prevXY = state.projection(prev)

          let toFrom = [prevXY, xy]
          state.svg
            .append("path")
            .attr("d", curve(toFrom))
            .attr("class", "lines")

          let text = state.svg
            .append("svg:foreignObject")
            .attr("width", state.container.width)
            .attr("height", state.container.height)
            .attr("class", "text")
            .append("xhtml:body")
            .attr(
              "style",
              `margin-left:${
                state.projection(middle.geometry.coordinates)[0] - 25
              }px;margin-top:${
                state.projection(middle.geometry.coordinates)[1] +
                (metadata.value.platform == "ios" ? 120 : 0)
              }px`
            )

          text
            .append("div")
            .attr("class", "distance")
            .append("span")
            .text(distance)
        }
      })

      dots.map((dot, i) => {
        let xy = state.projection(dot)

        state.svg
          .append("circle")
          .attr("r", 10)
          .attr("cx", xy[0])
          .attr("cy", xy[1])
          .attr("class", `dots dot-${i}`)
      })
    }
  }

  return {
    init,
    goto,
    updateLocation,
  }
}
