import _ from "lodash"
import { reactive, toRefs } from "vue"

import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import App from "@/modules/app"
import User from "@/modules/user"
import Helpers from "@/modules/helpers"
import Handicap from "@/modules/handicap"
import Firebase from "@/modules/thirdparty/firebase"
import { nSQL } from "@nano-sql/core"

const defaultValues = {
  loading: false,
  societies: [],
  currentSociety: null,
  updating: null,
  sorting: false,
}

const state = reactive({
  ...defaultValues,
})

export default function () {
  const init = async () => {
    const { query } = DB()
    nSQL("tc_societies").on("change", (e) => {
      if (state.updating) state.updating.cancel()
      state.updating = _.debounce(async () => {
        state.societies = await query({ table: "tc_societies" })
        state.updating = null
      }, 500)
      state.updating()
    })
    state.societies = await query({ table: "tc_societies" })
  }

  const clear = async () => {
    const { deleteAll } = DB()
    await deleteAll({
      table: `tc_societies`,
    })
  }

  const save = async (key, value) => {
    state[key] = value
  }

  const get = async (societyId) => {
    const { api } = API()
    return api({
      method: "GET",
      url: `/society/${societyId}`,
    })
  }

  const create = async ({ name, image, visibility, selectedMembers }) => {
    const { api } = API()
    const { upsert } = DB()
    const { logEvents } = Firebase()
    const { profile } = User()
    let res = await api({
      method: "PUT",
      url: `/user/${profile.value.uid}/society`,
      data: {
        name,
        image,
        visibility,
        members: selectedMembers,
      },
    })
    if (res.error) return res
    else {
      res.id = res._id
      await upsert({
        table: "tc_societies",
        data: res,
      })
      logEvents({ name: "create_society" })
      return res
    }
  }

  const members = async (society) => {
    const { api } = App()
    const { processData } = Handicap()
    let current = 1

    let members = []
    let handicapData = {}

    let res = await api({
      method: "GET",
      url: `/society/${society}/members?page=${current}`,
    })

    if (!res.error) {
      members = res.members
      res.handicapData.map((handicap) => {
        handicapData[handicap.golfLinkNo] = processData(
          handicap,
          handicap.handicapDetails.clubName == "The Clubhouse"
            ? "clubhouse"
            : handicap.country || "australia"
        )
      })
    }
    return { members, handicapData }
  }

  const editMember = async ({ nickname, golfLinkNo }) => {
    const { api } = App()
    let res = await api({
      method: "POST",
      url: `/society/${state.currentSociety.id}/member`,
      data: {
        nickname,
        golfLinkNo,
      },
    })
    if (!res.error) {
      let currentSociety = _.cloneDeep(state.currentSociety)
      let index = _.findIndex(
        currentSociety.members,
        (o) => o.golfLinkNo == golfLinkNo
      )
      currentSociety.members[index].nickname = nickname
      state.currentSociety = currentSociety
    }
    return res
  }

  const removeMember = async (golfLinkNo) => {
    const { api } = App()
    let res = await api({
      method: "DELETE",
      url: `/society/${state.currentSociety.id}/member`,
      query: `golfLinkNo=${golfLinkNo}`,
    })
    if (!res.error) {
      let currentSociety = _.cloneDeep(state.currentSociety)
      currentSociety.members = _.filter(
        currentSociety.members,
        (o) => o.golfLinkNo != golfLinkNo
      )
      state.currentSociety = currentSociety
    }
    return res
  }

  const updateMembers = async (members) => {
    const { api } = API()
    const { logEvents } = Firebase()
    logEvents({ name: "add_society_member" })
    let toDelete = _.differenceBy(
      state.currentSociety.members,
      members,
      "golfLinkNo"
    )
    await api({
      method: "PUT",
      url: `/society/${state.currentSociety.id}/member`,
      data: {
        members,
        toDelete,
      },
    })

    state.currentSociety = {
      ...state.currentSociety,
      members,
    }
    return {}
  }

  const remove = async (societyId) => {
    const { api } = API()
    const { deleteItem } = DB()
    let res = await api({
      method: "DELETE",
      url: `/society/${societyId}`,
    })
    if (!res.error) {
      await deleteItem({
        table: "tc_societies",
        data: ["id", "=", societyId],
      })
    }
    return res
  }

  const update = async (data) => {
    const { api } = API()
    const { upsert } = DB()
    const { openToast } = Helpers()

    let res = await api({
      method: "POST",
      url: `/society/${state.currentSociety.id}`,
      data: {
        name: state.currentSociety.name,
        image: state.currentSociety.image,
        visibility: state.currentSociety.visibility,
        settings: state.currentSociety.settings,
        ...data,
      },
    })

    if (res.error) {
      openToast({ color: "danger", message: "Error updating society" })
      return res
    }
    openToast({ message: "Society updated" })
    await save("currentSociety", {
      ..._.cloneDeep(state.currentSociety),
      ...data,
    })
    await upsert({
      table: "tc_societies",
      data: _.cloneDeep(data),
      where: ["id", "=", state.currentSociety.id],
    })
    return {}
  }

  const invite = async (email) => {
    const { api } = API()
    const { logEvents } = Firebase()
    const { profile } = User()
    let res = await api({
      method: "POST",
      url: `/society/${state.currentSociety.id}/invite`,
      data: {
        email,
        name: state.currentSociety.name,
        user: profile.value.name.first,
      },
    })
    if (!res.error) {
      await save("currentSociety", {
        ...state.currentSociety,
        invites: [...state.currentSociety.invites, { email }],
      })
      logEvents({ name: "society_invite_added" })
    }
    return res
  }

  const removeInvite = async (email) => {
    const { api } = API()
    const { logEvents } = Firebase()
    const { openToast } = Helpers()

    let res = await api({
      method: "DELETE",
      url: `/society/${state.currentSociety.id}/invite`,
      query: `email=${email}`,
    })
    if (res.error) openToast({ color: "danger", message: res.error })
    else {
      await save("currentSociety", {
        ...state.currentSociety,
        invites: _.filter(
          _.cloneDeep(state.currentSociety.invites),
          (o) => o.email != email
        ),
      })
      logEvents({ name: "society_invite_removed" })
      openToast({ color: "success", message: "Society invite removed" })
    }
    return res
  }

  const updateInvite = async (invite) => {
    const { api } = API()
    const { upsert, deleteItem } = DB()
    const { profile, invites, save } = User()

    let res = await api({
      method: "POST",
      url: `/user/${profile.value.uid}/society/invite`,
      data: {
        societyId: invite.societyData._id,
        email: invite.email,
        accepted: invite.accepted,
      },
    })
    if (!res.error) {
      let invitesList = _.cloneDeep(invites.value)
      let inviteIndex = _.findIndex(invitesList, (o) => o._id == invite._id)

      if (invite.accepted) {
        invite.societyData.id = invite.societyData._id
        await upsert({
          table: "tc_societies",
          data: invite.societyData,
        })
        invitesList[inviteIndex].accepted = invite.accepted
      } else {
        await deleteItem({
          table: "tc_societies",
          data: ["id", "=", invite.societyData._id],
        })
        invitesList.splice(inviteIndex, 1)
      }

      save({ id: "invites", data: { invites: invitesList } })
    }
    return {}
  }

  const updateOrder = async (newOrder) => {
    const { api } = API()
    const { profile } = User()
    let current = _.cloneDeep(state.currentSociety)
    let ordered = []
    let toUpdate = []

    state.sorting = true
    let me = _.findIndex(newOrder, (o) => o.user)
    if (me > -1)
      await update({
        settings: {
          ...current.settings,
          order: newOrder[me].order,
        },
      })

    current.members.map((member) => {
      if (!member.user) {
        let newData = _.find(newOrder, (o) => o.golfLinkNo == member.golfLinkNo)
        ordered.push({
          ...member,
          ...newData,
        })
        if (newData && member.order != newData.order) {
          toUpdate.push({
            golfLinkNo: member.golfLinkNo,
            order: newData.order,
          })
        }
      }
    })

    api({
      method: "POST",
      url: `/society/${state.currentSociety.id}/member/sort`,
      data: {
        toUpdate,
      },
    })

    await save("currentSociety", {
      ...current,
      members: ordered,
    })
    setTimeout(() => {
      state.sorting = false
    }, 500)
  }

  return {
    ...toRefs(state),

    init,
    clear,
    get,
    save,
    create,
    members,
    editMember,
    removeMember,
    updateMembers,
    remove,
    update,

    invite,
    removeInvite,
    updateInvite,
    updateOrder,
  }
}
