import _ from "lodash"
import moment from "moment"
import { reactive } from "vue"
import { Capacitor } from "@capacitor/core"
import inAppPurchase from "cordova-plugin-inapppurchase-2"
// import inAppPurchase from "cordova-plugin-inapppurchase-2/www/index-android"

import Membership from "@/modules/user/membership"

const OLD_MEMBERSHIP = "clubhouse_membership"
const OLD_HANDICAP = "clubhouse_membership"
const MEMBERSHIP_MONTHLY = "monthly_membership"
const MEMBERSHIP_YEARLY = "yearly_membership"

const state = reactive({
  error: null,
  products: [],
  restoring: false,
  store: null,
})

export default function () {
  const subscribe = async (product) => {
    const { update } = Membership()
    return inAppPurchase
      .subscribe(product.productId)
      .then(async (data) => {
        if (data.transactionId) {
          let expires = moment().add(1, "year").format()
          if (product.productId.includes("monthly"))
            expires = moment().add(1, "months").format()

          let type = "The Clubhouse Membership"
          type = `${type} (${product.productId})`

          await update({
            platform: Capacitor.getPlatform(),
            transaction: data,
            expires,
            type,
          })

          if (Capacitor.getPlatform() == "android") {
            await inAppPurchase.acknowledge(
              data.type,
              data.receipt,
              data.signature
            )
          }
        }
        return {}
      })
      .catch((err) => {
        return { err: err.message }
      })
  }

  const getSubscriptions = async () => {
    try {
      return inAppPurchase
        .getProducts([MEMBERSHIP_MONTHLY, MEMBERSHIP_YEARLY])
        .then((products) => products)
        .catch((err) => ({ err: err.message }))
    } catch (err) {
      return { err: err.message }
    }
  }

  const restorePurchase = async () => {
    const { update } = Membership()
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() == "ios") {
      try {
        await inAppPurchase
          .restorePurchases()
          .then(async (purchases) => {
            if (purchases && purchases.length) {
              purchases = _.orderBy(purchases, ["date"], ["desc"])
              let expires = moment(purchases[0].date).add(1, "year").format()
              if (purchases[0].productId.includes("monthly"))
                expires = moment(purchases[0].date).add(1, "month").format()

              let receipt = await inAppPurchase
                .getReceipt()
                .then((receipt) => receipt)
                .catch((err) => err)

              if (purchases[0].date && moment().format() <= expires) {
                let type = "The Clubhouse Membership"
                if (
                  [OLD_MEMBERSHIP, OLD_HANDICAP].includes(
                    purchases[0].productId
                  )
                )
                  type = `${type} (Legacy)`
                else type = `${type} (${purchases[0].productId})`

                await update({
                  platform: Capacitor.getPlatform(),
                  transaction: {
                    status: purchases[0].status,
                    transactionId: purchases[0].transactionId,
                    receipt,
                  },
                  expires,
                  type,
                })
              }
            }
            return {}
          })
          .catch(function (err) {
            return { err }
          })
      } catch (err) {
        return { err }
      }
    }
    return {}
  }

  return {
    getSubscriptions,
    restorePurchase,
    subscribe,
  }
}
