<template>
  <ion-page>
    <ion-tabs @ionTabsDidChange="tabChange">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar
        color="light"
        slot="bottom"
        v-if="!currentTournament || currentTab !== 'rounds'"
        ref="tabs"
      >
        <ion-tab-button tab="home" href="/home">
          <div class="ion-activatable">
            <ion-ripple-effect class="ripple" />
            <ion-icon :icon="homeOutline" />
            <ion-label>Home</ion-label>
          </div>
        </ion-tab-button>
        <ion-tab-button tab="members" href="/members">
          <div class="ion-activatable">
            <ion-ripple-effect />
            <ion-icon :icon="peopleOutline" />
            <ion-label>Members</ion-label>
          </div>
        </ion-tab-button>
        <ion-tab-button tab="profile" href="/courses">
          <div class="ion-activatable">
            <ion-ripple-effect />
            <ion-icon :icon="golfOutline" />
            <ion-label>Daily</ion-label>
          </div>
        </ion-tab-button>
        <ion-tab-button tab="rounds" href="/rounds">
          <div class="ion-activatable">
            <ion-ripple-effect />
            <ion-icon :icon="readerOutline" />
            <ion-label>Scoring</ion-label>
          </div>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import { ref, onMounted } from "vue"
import {
  IonPage,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonRippleEffect,
  IonLabel,
  IonIcon,
} from "@ionic/vue"

import {
  homeOutline,
  peopleOutline,
  golfOutline,
  readerOutline,
} from "ionicons/icons"

import App from "@/modules/app"
import Scoring from "@/modules/tournament/scoring"
import Pusher from "@/modules/thirdparty/pusher"
import User from "@/modules/user"

import router from "../router"
import { Capacitor } from "@capacitor/core"
import { Keyboard } from "@capacitor/keyboard"

const { loading, keyboard } = App()
const { currentTournament } = Scoring()
const { pusher } = Pusher()
const { loggingIn } = User()

const tabs = ref(null)
const currentTab = ref(router.currentRoute.value.name)

const tabChange = () => {
  currentTab.value = router.currentRoute.value.name
}

onMounted(() => {
  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener("keyboardDidHide", () => {
      if (tabs.value && tabs.value.$el)
        tabs.value.$el.classList.remove("tab-bar-hidden")
    })
  }
})
</script>

<style lang="scss" scoped>
.tab-bar-hidden {
  display: flex !important;
}

ion-tab-bar {
  background: var(--ion-color-white);
  border-top: 1px solid var(--ion-color-medium);
  padding-bottom: var(--ion-safe-area-bottom);
  height: 60px;

  ion-tab-button {
    background: var(--ion-color-white);
    color: var(--ion-color-primary);
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    div {
      height: 100%;
      padding: 10px 0;

      ion-ripple-effect {
        color: var(--ion-color-secondary) !important;
      }

      ion-icon {
        height: 60%;
        font-size: 1.5rem;
      }

      ion-label {
        display: block;
        font-family: Syne, sans-serif;
        font-size: 0.7rem;
        height: 25%;
        text-transform: uppercase;
      }
    }

    &.tab-selected {
      ion-icon {
        color: var(--ion-color-secondary);
      }

      ion-label {
        color: var(--ion-color-secondary);
      }
    }
  }
}
</style>
