import API from "@/modules/helpers/api"

export default function () {
  const course = async (id_course, data) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "POST",
      url: `/igolf/course/${id_course}`,
      data,
    })
  }

  const courseList = async (query) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "post",
      url: "/igolf/request?course",
      data: {
        action: "CourseList",
        body: {
          resultsPerPage: 20,
          active: 1,
          countryFormat: 3,
          courseName: query,
        },
      },
    })
  }

  const courseScoring = async (id_course) => {
    let data = await Promise.all([
      courseDetails(id_course),
      teesList(id_course),
      scorecardsList(id_course),
    ])
    return {
      ...data[0],
      ...data[1],
      ...data[2],
    }
  }

  const courseDetails = async (id_course) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "post",
      url: "/igolf/request?courseDetails",
      data: {
        action: "CourseDetails",
        body: {
          id_course,
          detailLevel: 2,
          countryFormat: 4,
          stateFormat: 4,
        },
      },
    })
  }

  const teesList = async (id_course) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "post",
      url: "/igolf/request?tees",
      data: {
        action: "CourseTeeDetails",
        body: {
          detailLevel: 2,
          id_course,
        },
      },
    })
  }

  const scorecardsList = async (id_course) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "post",
      url: "/igolf/request?scorecard",
      data: {
        action: "CourseScorecardDetails",
        body: {
          id_course,
        },
      },
    })
  }

  const courseGPS = async (id_course) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "post",
      url: "/igolf/request?gps",
      data: {
        action: "CourseGPSDetails",
        body: {
          id_course,
        },
      },
    })
  }

  const courseGPSVector = async (id_course) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "post",
      url: "/igolf/request?gps-vector",
      data: {
        action: "CourseGPSVectorDetails",
        body: {
          id_course,
        },
      },
    })
  }

  const save = async (data) => {
    const { api } = API()
    return api({
      bypass: true,
      method: "post",
      url: "/igolf/save",
      data,
    })
  }

  const formatPoints = (asset) => {
    let courseVectorGPS = asset
    if (courseVectorGPS)
      Object.keys(courseVectorGPS).map((key) => {
        courseVectorGPS[key] = format(courseVectorGPS[key])
        if (key == "Holes") {
          courseVectorGPS[key].Hole.map((hole, index) => {
            Object.keys(hole).map((key2) => {
              courseVectorGPS[key].Hole[index][key2] = format(hole[key2])
            })
          })
        }
      })
    return courseVectorGPS
  }

  return {
    course,
    courseList,
    courseDetails,
    courseGPS,
    courseGPSVector,
    teesList,
    scorecardsList,
    courseScoring,
    save,
    formatPoints,
  }
}

const format = (asset) => {
  if (asset.ShapeCount > 0) {
    asset.Shapes.Shape.map((shape) => {
      let formatted = []
      let points = shape.Points.split(",")
      points.map((point) => {
        let raw = point.split(" ")
        formatted.push([parseFloat(raw[0]), parseFloat(raw[1])])
      })
      shape.Points = formatted
    })
  }
  return asset
}
