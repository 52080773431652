import { createApp } from "vue"
import { IonicVue } from "@ionic/vue"

import AppView from "./AppView.vue"
import router from "./router"

import "./theme/style.scss"

const app = createApp(AppView).use(IonicVue).use(router)

router.isReady().then(async () => {
  app.mount("#app")
  app.config.errorHandler = (err, vm, info) => {
    // console.log(err)
    // console.log(err.message)
  }
})
