import _ from "lodash"

import API from "@/modules/helpers/api"
import Helpers from "@/modules/helpers"
import User from "@/modules/user"

export default function () {
  const update = async (form) => {
    const { api } = API()
    const { openToast } = Helpers()
    const { profile, save } = User()
    let res = await api({
      method: "POST",
      url: `/user/${profile.value.uid}/settings`,
      data: {
        settings: form,
      },
    })

    if (res.error)
      return openToast({ color: "danger", message: "Error updating settings" })
    else {
      await save({
        id: "settings",
        data: form,
      })
      return openToast({ message: "Settings updated" })
    }
  }

  return {
    update,
  }
}
