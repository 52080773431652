<template>
  <ion-app :class="{ keyboard }" ref="view">
    <div class="loading" v-if="loading">
      <ion-row class="ion-align-items-center">
        <ion-col size="12" class="ion-text-center">
          <img
            alt="The Clubhouse"
            src="/images/clubhouse/woman-logo-outline.webp"
          />
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </ion-col>
      </ion-row>
    </div>
    <ion-router-outlet />
  </ion-app>
</template>

<script setup>
import { computed, ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import {
  IonApp,
  IonRouterOutlet,
  IonRow,
  IonCol,
  IonProgressBar,
} from "@ionic/vue"

import App from "@/modules/app"
import Helpers from "@/modules/helpers"
import Pusher from "@/modules/thirdparty/pusher"
import Firebase from "@/modules/thirdparty/firebase"
import User from "@/modules/user"

import AuthModal from "@/components/auth/AuthModal"
import SocietyInvites from "@/components/profile/settings/SocietyInvites"

const { init, loading, keyboard } = App()
const { initialize } = Firebase()
const { openModal } = Helpers()
const { loggedIn, loadingUser, auth } = User()
const { connect } = Pusher()
const route = useRoute()

const view = ref(null)

const path = computed(() => route.query)

onMounted(async () => {
  setTimeout(async () => {
    try {
      connect()
      await initialize()
      await init(view.value)
      await User().init()
      loading.value = false
    } catch (err) {
      loading.value = false
    }
    if (!loggedIn.value) {
      let data = { screen: "welcome" }
      if (path.value.mode == "resetPassword")
        data = { screen: "reset", query: path.value.oobCode }
      else if (path.value.referralCode)
        data = { screen: "signup", query: path.value.referralCode }
      openModal({
        page: AuthModal,
        customHeader: true,
        data,
      })
    } else {
      if (path.value.view == "societyInvites") {
        openModal({
          page: SocietyInvites,
        })
      }
    }
  }, 1000)
})
</script>

<style lang="scss" scoped>
.loading {
  background: var(--ion-color-white);
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 9999;
  ion-row {
    height: 100%;

    img {
      width: 75%;
    }

    ion-progress-bar {
      text-align: center;
      margin: 50px auto;
      width: 50%;
    }
  }
}
</style>
