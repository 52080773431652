import _ from "lodash"
import moment from "moment"
import { nSQL } from "@nano-sql/core"
import { computed, reactive, toRefs } from "vue"
import {
  deleteUser,
  signOut,
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  onAuthStateChanged,
  updateEmail,
} from "firebase/auth"

import Appstore from "@/modules/thirdparty/appstore"
import App from "@/modules/app"
import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import Helpers from "@/modules/helpers"
import Daily from "@/modules/user/daily"
import Society from "@/modules/user/society"
import Member from "@/modules/user/member"
import Handicap from "@/modules/handicap"
import Scoring from "@/modules/tournament/scoring"
import Tournament from "@/modules/tournament"

import Firebase from "@/modules/thirdparty/firebase"

import AuthModal from "@/components/auth/AuthModal"
import SetupModal from "@/components/setup/SetupModal"
import Pusher from "@/modules/thirdparty/pusher"

const account = [
  "auth",
  "profile",
  "membership",
  "settings",
  "passport",
  "invites",
]
const attributes = [
  "tournaments",
  "courses",
  "societies",
  "handicaps",
  "members",
]

const defaultSettings = {
  home: ["latest", "societies", "daily"],
  helpers: {
    app: true,
    tutorial: true,
    instagram: true,
    passport: true,
  },
  instagram: true,
  members: {
    showMe: true,
    clubName: true,
    handicapNo: true,
    dots: true,
    handicapChange: true,
    sort: "hcpSort-asc",
  },
  gps: {
    units: "meters",
  },
}

const user = {
  auth: {},
  currentEmail: null,
  created: false,
  error: null,
  profile: {},
  membership: {},
  passport: {},
  invites: [],
  settings: _.cloneDeep(defaultSettings),
  loadingUser: false,
  loadingHandicap: false,
  updating: null,
  referralUser: "",
}

const state = reactive(_.cloneDeep(user))

export default function () {
  const init = async () => {
    nSQL("tc_users").on("change", (e) => {
      if (state.updating) state.updating.cancel()
      state.updating = _.debounce(async () => {
        await saveAttributes()
        state.updating = null
      }, 500)
      state.updating()
    })

    await saveAttributes()

    await Promise.all(
      attributes.map(async (attribute) => {
        let functions = accountAttributes(attribute)
        if (functions) await functions.init()
      })
    )

    onAuthStateChanged(getAuth(), async (user) => {
      state.loadingUser = true
      if (user) {
        try {
          await save({
            id: "auth",
            data: {
              fid: user.uid,
              expiryTime: user.stsTokenManager.expirationTime,
              accessToken: user.stsTokenManager.accessToken,
            },
          })
          if (state.created) await create()
          await get()
        } catch (err) {
          await clear()
        }
      } else await clear()
      state.loadingUser = false
    })
  }

  const saveAttributes = async () => {
    const { query } = DB()
    let userData = await query({ table: "tc_users" })
    userData.map((data) => {
      if (data.data) {
        if (data.id == "invites") state[data.id] = data.data.invites
        else state[data.id] = data.data
      }
    })
  }

  const save = async ({ id, data, overwrite = false }) => {
    const { upsert } = DB()
    await upsert({
      table: "tc_users",
      data: {
        id,
        data: {
          ...(state[id] && !overwrite ? state[id] : {}),
          ...data,
        },
      },
    })
  }

  const clear = async () => {
    const { loading } = App()
    const { upsert } = DB()
    loading.value = true
    try {
      await Promise.all(
        attributes.map(async (attribute) => {
          let functions = accountAttributes(attribute)
          if (functions) await functions.clear()
        })
      )
      await Promise.all(
        account.map(async (id) => {
          await upsert({
            table: "tc_users",
            data: {
              id,
              data: user[id],
            },
          })
        })
      )
    } catch (err) {
      return clear()
    }
    setTimeout(() => {
      loading.value = false
    }, 2500)
  }

  /**
   * CHECK TOKEN
   */
  const checkToken = async (force) => {
    const { query } = DB()
    let auth = await query({ table: "tc_users", where: ["id", "=", "auth"] })
    if (
      auth &&
      (await getAuth().currentUser) &&
      (force ||
        moment().diff(
          moment(auth[0].data.expiryTime, "x").format(),
          "minutes"
        ) >= -5)
    ) {
      await save({
        id: "auth",
        data: {
          expiryTime: moment().add(60, "minutes").format("x"),
          accessToken: await getAuth().currentUser.getIdToken(true),
        },
      })
    }

    return {}
  }

  /**
   * UPDATE FIREBASE TOKEN
   */
  const updateToken = async () => {
    const { api } = API()
    let res = await api({
      method: "GET",
      url: `/user/${state.profile.uid}`,
    })
    if (!res.error) return signInWithCustomToken(getAuth(), res.token)
    return { error: true }
  }

  /**
   * GET USER PROFILE
   */
  const get = async (force = false) => {
    const { api } = API()
    const { restorePurchase } = Appstore()
    const { dump, deleteAll, deleteItem } = DB()
    const { openModal, openToast } = Helpers()
    const { getUser, getMembers } = Handicap()
    const { setUserIds } = Firebase()

    state.loadingUser = true
    if (
      force ||
      !state.profile.uid ||
      !state.profile.updated ||
      moment().diff(state.profile.updated, "minutes") >= 60
    ) {
      let res = await api({
        method: "GET",
        url: "/user",
      })
      let started = moment().format()
      if (res.error) {
        openModal({
          page: AuthModal,
          customHeader: true,
          data: {
            screen: "login",
          },
        })
        openToast({
          color: "danger",
          message: "Login error",
        })
        state.loadingUser = false
        return
      }

      Object.entries(res).map(async (val) => {
        if (attributes.includes(val[0])) {
          await deleteAll({
            table: `tc_${val[0]}`,
          })
          await dump({
            table: `tc_${val[0]}`,
            data: val[1],
          })
        } else {
          if (val[0] == "profile") {
            val[1].updated = moment().format()
          } else if (val[0] == "invites") {
            val[1] = { invites: val[1] }
          }
          await save({
            id: val[0],
            data: val[1],
            overwrite: true,
          })
        }
      })

      let tries = 0
      while (tries <= 10) {
        await new Promise((res) => setTimeout(res, 1000))
        if (state.profile.updated && state.profile.updated >= started)
          tries = 10
        tries++
      }
      setUserIds(state.profile.uid)
      Promise.all([getUser(), getMembers(), restorePurchase()])
    }
    state.loadingUser = false
    eventListener()
    return {}
  }

  /**
   * UPDATE PROFILE
   */
  const update = async (form) => {
    const { api } = API()
    const { openToast } = Helpers()

    let auth = getAuth()
    let error = null

    let data = {}
    Object.entries(form).map((array) => {
      data[array[0]] = array[1]
    })

    if (data.email && data.email != state.profile.email) {
      let token = await updateToken()
      if (!token.error)
        await updateEmail(auth.currentUser, data.email)
          .then(() => {})
          .catch(() => {
            error = "Error updating email"
          })
      else error = "Error updating email"
    } else {
      delete data.email
    }

    if (error)
      return openToast({
        color: "danger",
        message: "Error updating email",
      })

    let res = await api({
      method: "POST",
      url: `/user/${state.profile.uid}`,
      data,
    })

    if (res.error)
      return openToast({
        color: "danger",
        message: "Error updating details",
      })

    await save({
      id: "profile",
      data: data,
    })

    openToast({
      color: "success",
      message: "Updated details",
    })
  }

  /**
   * LOGIN WITH FIREBASE
   */
  const login = async ({ email, password }) => {
    const auth = getAuth()
    let res = await signInWithEmailAndPassword(auth, email, password)
      .then(async (data) => ({
        email: data.user.email.toLowerCase(),
        fid: data.user.uid,
      }))
      .catch((err) => err)

    if (["auth/wrong-password", "auth/user-not-found"].includes(res.code))
      return { error: "Wrong email/password combination" }
    else if (res.message) return { error: res.message }
    return {}
  }

  /**
   * REGISTER WITH FIREBASE
   */
  const register = async ({ name, email, password, referral }) => {
    const auth = getAuth()

    let nameSplit = name.split(" ")
    let first = nameSplit[0].trim()
    nameSplit.shift()
    email = email.toLowerCase()

    state.created = true
    let firebase = await createUserWithEmailAndPassword(auth, email, password)
      .then((data) => ({ fid: data.user.uid }))
      .catch((error) => ({ error }))

    if (firebase.error) {
      state.created = false
      if (firebase.error.code == "auth/email-already-in-use")
        return { error: "Email already in use" }
      else return { error: "Registration error. contact help@theclubhouse.ai" }
    }

    state.profile = {
      fid: firebase.fid,
      email,
      name: {
        first: first,
        last: nameSplit.join(" ").trim(),
      },
      referral,
    }

    return {}
  }

  /**
   * CREATE USER
   */
  const create = async () => {
    const { api } = API()
    const { openToast } = Helpers()

    const auth = getAuth()

    let res = await api({
      method: "PUT",
      url: "/user",
      data: state.profile,
    })

    state.created = false
    if (res.error) {
      await deleteUser(auth.currentUser)
      openToast({
        color: "danger",
        message: "Error creating user, please contact help@theclubhouse.ai",
      })
    }
    return {}
  }

  /**
   * LOGOUT
   */
  const logout = async () => {
    const { openToast } = Helpers()
    const { reset } = Firebase()
    try {
      await signOut(getAuth())
      await reset()
    } catch (err) {
      openToast({
        color: "danger",
        message: "Error logging out, please contact help@theclubhouse.ai",
      })
    }
  }

  /**
   * RESET PASSWORD
   */
  const resetPassword = async ({ email }) => {
    const auth = getAuth()
    let res = await sendPasswordResetEmail(auth, email)
      .then(() => ({
        success: "Reset email sent, please check your email.",
      }))
      .catch((err) => err)

    if (res.message) return { error: res.message }
    else
      return {
        success: "Reset email sent, please check your email.",
      }
  }

  const eventListener = async () => {
    const { status, pusher, connect } = Pusher()

    let tries = 0
    while (tries <= 10) {
      if (status.value) tries = 11
      else tries++
      await new Promise((res) => setTimeout(res, 1000))
    }

    if (!status.value) {
      await connect()
      return eventListener()
    }

    let userChannel = pusher.value.channel(
      `private-encrypted-user-${state.profile.uid}`
    )

    if (!userChannel || !userChannel.subscribed) {
      let channel = pusher.value.subscribe(
        `private-encrypted-user-${state.profile.uid}`
      )

      channel.bind("society-invite", async () => {
        get(true)
      })
    }
  }

  /**
   * COMPUTED
   */
  const loggedIn = computed(() => {
    return state.auth && state.auth.fid && state.auth.accessToken
  })

  const handicap = computed(() => {
    const { handicaps } = Handicap()
    if (state.profile.golfLinkNo && handicaps.value[state.profile.golfLinkNo])
      return handicaps.value[state.profile.golfLinkNo]
    return null
  })

  const displayName = computed(() => {
    if (!loggedIn.value || !state.profile.name) return ""
    return (
      state.profile.nickname ||
      `${state.profile.name.first}${
        state.profile.name.last ? ` ${state.profile.name.last}` : ""
      }`
    )
  })

  const validMembership = computed(() => {
    return (
      state.profile.lifetimeMembership ||
      (state.membership &&
        state.membership.expires &&
        moment().format() <= moment(state.membership.expires).format())
    )
  })

  const country = computed(() => {
    let country = "international"
    if (state.profile) {
      if (state.profile.dotGolf) return state.profile.dotGolf
      else if (
        state.profile.gaHandicap ||
        (state.profile.golfLinkNo && !state.profile.clubhouseHandicap)
      )
        return "australia"
    }
    return country
  })

  const accountAttributes = (attribute) => {
    if (attribute == "members") return Member()
    else if (attribute == "handicaps") return Handicap()
    else if (attribute == "courses") return Daily()
    else if (attribute == "societies") return Society()
    else if (attribute == "tournaments") return Tournament()
    else if (attribute == "scoring") return Scoring()
    else return null
  }

  return {
    ...toRefs(state),
    init,
    save,
    // AUTH
    get,
    update,
    // FIREBASE
    register,
    login,
    logout,
    checkToken,
    resetPassword,
    // COMPUTED
    loggedIn,
    handicap,
    displayName,
    validMembership,
    country,
  }
}
