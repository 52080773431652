<template>
  <div class="form" :class="{ 'c-expand': !keyboard }">
    <h1>Forgot Password</h1>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="email"
          fill="outline"
          inputmode="email"
          label-placement="floating"
          label="Email"
          mode="md"
          type="email"
          :disabled="loading"
          :value="email"
          @ionInput="email = $event.target.value"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <global-button
          expand="block"
          :disabled="disabled || loading"
          :loading="loading"
          text="Request reset link"
          @click="forgot"
        ></global-button>
      </ion-col>
    </ion-row>
  </div>
  <ion-row class="ion-align-items-center c-modal-footer" v-if="!keyboard">
    <ion-col class="ion-text-center" size="12">
      <p class="light">
        Already have an account?
        <span class="link" @click="changeStep('login')">Login</span>
      </p>
    </ion-col>
  </ion-row>
</template>

<script setup>
import { reactive, ref, toRefs, watch } from "vue"
import { IonRow, IonCol, IonInput } from "@ionic/vue"
import App from "@/modules/app"
import Helpers from "@/modules/helpers"
import User from "@/modules/user"

import GlobalButton from "@/components/global/GlobalButton"

const { openModals, keyboard } = App()
const { openToast, validEmail } = Helpers()
const { resetPassword } = User()

const props = defineProps({
  changeStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const loading = ref(false)
const error = ref(null)
const disabled = ref(true)

const form = reactive({
  email: "",
})

watch(form, (updated) => {
  disabled.value = !(
    updated.email &&
    validEmail(updated.email) &&
    updated.email.length >= 5
  )
})

const { email } = toRefs(form)

const forgot = async () => {
  loading.value = true
  let res = await resetPassword(form)
  loading.value = false
  form.email = ""
  return openToast({
    color: res.error ? "danger" : "success",
    message: res.error || res.success,
  })
}
</script>

<style lang="scss" scoped></style>
