import _ from "lodash"
import moment from "moment"
import { reactive, toRefs } from "vue"

import DB from "@/modules/helpers/db"
import API from "@/modules/helpers/api"
import App from "@/modules/app"
import Helpers from "@/modules/helpers"
import Member from "@/modules/user/member"
import User from "@/modules/user"
import { nSQL } from "@nano-sql/core"
import Passport from "@/modules/user/passport"

const state = reactive({
  handicaps: {},
  updatingHandicaps: false,
  updating: null,
})

export default function () {
  const init = async () => {
    nSQL("tc_handicaps").on("change", (e) => {
      if (state.updating) state.updating.cancel()
      state.updating = _.debounce(async () => {
        await set()
        state.updating = null
      }, 5000)
      state.updating()
    })
    await set()
    return {}
  }

  const set = async () => {
    const { query } = DB()
    try {
      let handicaps = {}
      let data = await query({ table: "tc_handicaps" })
      data.map((o) => {
        handicaps[o.golfLinkNo] = o
      })
      state.handicaps = handicaps
      state.updatingHandicaps = false
    } catch (err) {
      return err
    }
  }

  const clear = async () => {
    const { deleteAll } = DB()
    await deleteAll({
      table: `tc_handicaps`,
    })
  }

  const getUser = async () => {
    const { loadingHandicap, profile, country } = User()
    loadingHandicap.value = true
    if (profile.value.golfLinkNo) {
      await check(profile.value.golfLinkNo, 60, country.value)
      let tries = 0
      while (tries <= 10) {
        await new Promise((res) => setTimeout(res, 1000))
        if (state.handicaps[profile.value.golfLinkNo]) tries = 10
        tries++
      }
    }
    Passport().update()
    loadingHandicap.value = false
  }

  const getMembers = async () => {
    const { members, updating } = Member()
    let tries = 0
    state.updatingHandicaps = true
    while ((!members.value.length || updating.value) && tries <= 10) {
      await new Promise((res) => setTimeout(res, 1000))
      tries++
    }
    if (members.value.length) {
      let total = Math.ceil(members.value.length / 50)
      await Promise.all(
        _.times(total, String).map((val, index) => {
          memberRequest(index + 1)
        })
      )
    }
    state.updatingHandicaps = false
  }

  const memberRequest = async (current) => {
    const { api } = API()
    const { dump } = DB()
    const { profile } = User()

    let res = await api({
      method: "GET",
      url: `/user/${profile.value.uid}/member?page=${current}`,
    })
    if (!res.error) {
      let details = res.map((o) => ({
        ...o,
        _id: o.golfLinkNo,
        last: o.rounds.length
          ? o.rounds[0].date
          : moment("1960-01-01").format(),
      }))
      await dump({
        table: `tc_handicaps`,
        data: details,
      })
    }
  }

  /**
   * Create Handicap
   */
  const create = async ({ previousHandicap }) => {
    const { api } = API()
    const { profile } = User()

    return api({
      method: "POST",
      url: `/handicap/create`,
      data: {
        user: profile.value.uid,
        manager: profile.value.uid,
        previousHandicap,
      },
    })
  }

  /**
   * Link Account
   */
  const linkAccount = async ({ email, membershipNo, password, country }) => {
    const { api } = API()
    const { profile } = User()
    let encoded = Buffer.from(password).toString("base64")
    let tries = 0
    let find = null
    while (
      tries < 2 &&
      (!find || (find.error && find.error.includes("try again")))
    ) {
      find = await api({
        custom: true,
        method: "POST",
        url: `${process.env.VUE_APP_API_EXT_URL}/${
          country == "australia" ? "ga" : "dotGolf"
        }/link`,
        data: {
          uid: profile.value.uid,
          email,
          membershipNo,
          encoded,
          country,
        },
      })
      tries++
    }

    if (find.error) return find
    else if (find.Description) return { error: find.Description }
    else if (find && find.loggedIn) return {}
    return {
      error: "Please try again. If error persists contact help@theclubhouse.ai",
    }
  }

  const dotGolfSync = async (membershipNo) => {
    const { api } = API()
    await api({
      custom: true,
      method: "POST",
      url: `${process.env.VUE_APP_API_EXT_URL}/dotGolf/sync`,
      data: {
        golfLinkNo: membershipNo,
      },
    })
  }

  /**
   * Check Handicap Number
   */
  const check = async (golfLinkNo, months = 0, country = "australia") => {
    const { api } = API()
    const { upsert } = DB()

    let handicap = await api({
      method: "GET",
      url: `/handicap/${golfLinkNo}?country=${country}${
        months ? `&months=${months}` : ""
      }`,
    })

    if (
      golfLinkNo.length < 12 &&
      country == "australia" &&
      ((handicap.error && handicap.error == "Handicap number not found") ||
        (months != 0 &&
          !handicap.error &&
          (!handicap.updated || moment().diff(handicap.updated, "months") > 6)))
    ) {
      let tries = 0
      let find = null

      while (
        tries < 2 &&
        (!find || (find.error && find.error.includes("try again")))
      ) {
        find = await api({
          url: `${process.env.VUE_APP_API_EXT_URL}/ga/sync`,
          method: "POST",
          data: {
            golfLinkNo,
            months,
          },
          custom: true,
        })
        tries++
      }
      if (find && find.handicapDetails) {
        return check(golfLinkNo, months, country)
      } else if (find.error) return find
      else if (find.Description) return { error: find.Description }
      return {
        error:
          "Please try again. If error persists contact help@theclubhouse.ai",
      }
    }

    if (!handicap.error) {
      handicap.last = handicap.rounds.length
        ? handicap.rounds[0].date
        : moment("1960-01-01").format()
      try {
        await upsert({
          table: "tc_handicaps",
          data: handicap,
        })
      } catch (err) {
        return handicap
      }
    }
    return handicap
  }

  const syncHandicap = async () => {
    const { api } = API()
    const { profile, get } = User()

    state.loadingHandicap = true
    await api({
      method: "POST",
      custom: true,
      url: `${process.env.VUE_APP_API_EXT_URL}/clubhouse/sync`,
      data: {
        golfLinkNo: profile.value.golfLinkNo,
      },
    })
    get(true)
    state.loadingHandicap = false
  }

  /**
   * Check Round data
   */
  const rounds = async (golfLinkNo, country = "australia") => {
    const { api } = App()
    return api({
      method: "GET",
      url: `/handicap/${golfLinkNo}/rounds?country=${country}`,
    })
  }

  const calculateDaily = ({ handicap, tees, display = false }) => {
    if (!handicap || !tees) return "-"
    let dailyHandicap =
      (handicap / (tees.teeHoles.toString().includes("9") ? 2 : 1)) *
      (tees.slopeRating / 113)
    dailyHandicap += tees.scratchRating - tees.par
    dailyHandicap *= 0.93
    dailyHandicap = Math.floor(dailyHandicap + 0.49)
    if (isNaN(dailyHandicap)) return "-"
    if (display && dailyHandicap < 0) dailyHandicap = `+${-1 * dailyHandicap}`
    return dailyHandicap
  }

  return {
    ...toRefs(state),
    init,
    clear,

    getUser,
    getMembers,

    linkAccount,
    dotGolfSync,
    check,

    create,
    syncHandicap,
    rounds,

    calculateDaily,
  }
}
