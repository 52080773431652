<template>
  <div class="c-inner-padding">
    <h2>Society Invites</h2>
    <ion-row class="ion-align-items-center c-placeholder" v-if="loading">
      <ion-col class="ion-text-center" size="12">
        <ion-spinner name="dots"></ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-align-items-center c-placeholder"
      v-else-if="!inviteList.length"
    >
      <ion-col class="ion-text-center" size="12">
        <p>No invites received</p>
      </ion-col>
    </ion-row>
    <div class="table add-scroll" v-else>
      <ion-row
        class="row ion-align-items-center"
        :key="`invite-${index}`"
        v-for="(invite, index) in inviteList"
      >
        <ion-col size="6">
          <span class="nickname">{{ invite.societyData.name }}</span>
        </ion-col>
        <ion-col class="ion-text-right" size="6">
          <ion-button
            :style="{ marginRight: '10px' }"
            color="success"
            class="button-tiny"
            size="small"
            @click="status(invite, true)"
            v-if="!invite.accepted"
            >Accept</ion-button
          >
          <ion-button
            color="danger"
            class="button-tiny"
            size="small"
            @click="status(invite, false)"
            >Remove</ion-button
          >
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash"
import { computed, ref } from "vue"
import { IonRow, IonCol, IonIcon, IonSpinner, IonButton } from "@ionic/vue"
import { closeOutline } from "ionicons/icons"
import Helpers from "@/modules/helpers"
import Society from "@/modules/user/society"
import User from "@/modules/user"

const { openPopover } = Helpers
const { updateInvite } = Society()
const { invites, profile } = User()

const loading = ref(false)
const error = ref(null)

const inviteList = computed(() => {
  return _.orderBy(
    _.filter(_.cloneDeep(invites.value), (o) =>
      [true, null, undefined].includes(o.accepted)
    ),
    ["accepted"],
    ["desc"]
  )
})

const status = async (invite, updated) => {
  loading.value = true
  await updateInvite({ ...invite, accepted: updated })
  loading.value = false
}
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 5px;
  display: inline-block;
  font-size: 0.5rem;
  padding: 2.5px 5px;
  text-transform: uppercase;
  vertical-align: middle;

  &:first-child {
    margin-right: 5px;
  }

  &.accepted {
    background: var(--ion-color-success);
    color: var(--ion-color-white);
  }

  &.cancel {
    background: var(--ion-color-danger);
    color: var(--ion-color-white);
  }

  &.pending {
    background: var(--ion-color-light);
  }
}

ion-icon {
  font-size: 1.15rem;
  vertical-align: middle;
}
</style>
