import { computed, reactive, toRefs } from "vue"
import Pusher from "pusher-js/with-encryption"

import API from "@/modules/helpers/api"

const state = reactive({
  status: null,
  pusher: null,
  socketId: null,
  connecting: null,
})

export default function () {
  const connect = () => {
    const { api } = API()

    let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      encrypted: true,
      authorizer: (channel) => {
        return {
          authorize: async (socketId, callback) => {
            let auth = await api({
              bypass: true,
              method: "GET",
              url: `/pusher`,
              query: `socketId=${socketId}&channelName=${channel.name}`,
            })
            callback(null, auth)
          },
        }
      },
    })

    pusher.connection.bind("connected", (e) => {
      state.pusher = pusher
      state.socketId = e.socket_id
    })

    pusher.connection.bind("disconnected", () => {
      state.pusher = null
      state.socketId = null
    })

    pusher.connection.bind("error", async (err) => {})

    return {}
  }

  const disconnect = async () => {
    if (state.pusher) state.pusher.disconnect()
  }

  const status = computed(() => {
    return !(
      !state.pusher ||
      !state.pusher.key ||
      !state.pusher.connection ||
      !state.pusher.connection.state ||
      state.pusher.connection.state == "disconnected"
    )
  })

  return {
    ...toRefs(state),
    connect,
    disconnect,
    status,
  }
}
