import _ from "lodash"
import { reactive, toRefs } from "vue"
import moment from "moment/moment"

import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import User from "@/modules/user/index"
import Handicap from "@/modules/handicap"

const countryList = require("@/modules/json/countries.json")
const top100 = require("@/modules/json/top100.json")

const state = reactive({
  grid: false,
  country: null,
})

export default function () {
  const update = async () => {
    const { api } = API()
    const { dump } = DB()
    const { handicaps } = Handicap()
    const { profile } = User()

    let data = {
      courses: [],
      played: [],
      countries: [],
      top100: _.cloneDeep(top100),
    }

    let handicapRounds = []
    if (handicaps.value[profile.value.golfLinkNo])
      handicapRounds = _.cloneDeep(
        handicaps.value[profile.value.golfLinkNo].rounds
      )
    let rounds = await api({
      method: "GET",
      url: `/user/${profile.value.uid}/passport/rounds`,
    })

    handicapRounds.concat(rounds).map((round) => {
      data.played.push({
        name: round.course,
        id: round.course
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .split(" ")
          .join("-")
          .replace("--", "-"),
        country: countryCheck(round.country),
      })
    })

    data.played = _.filter(
      _.uniqBy(data.played, "name"),
      (o) => o.name != "Artificially Generated Score" && o.name
    )

    data.countries = _.map(
      _.orderBy(_.uniqBy(data.played, "country"), ["country"], ["asc"]),
      (o) => ({ country: o.country, ...countryList[o.country] })
    )

    data.courses = await api({
      method: "POST",
      url: `/passport`,
      data: {
        courses: data.played,
      },
    })

    data.played = data.played.map((course) => {
      let exists = _.find(
        data.courses,
        (o) =>
          o.name == course.name ||
          (o.variations &&
            (o.variations.includes(course.name) ||
              o.variations.includes(course.id)))
      )
      if (exists) {
        Object.keys(exists.top100).map((list) => {
          let listIndex = _.findIndex(data.top100, (o) => o.list == list)
          if (listIndex > -1) data.top100[listIndex].played++
        })
      }
      return exists || course
    })

    await dump({
      table: "tc_users",
      data: [
        {
          _id: "passport",
          data,
          updated: moment().format(),
        },
      ],
    })
    return {}
  }

  const setCountry = (country = null) => {
    state.country = country
  }

  const toggleView = (val = null) => {
    if (val != null) state.grid = val
    else state.grid = !state.grid
  }

  const countryCheck = (country) => {
    if (country == "united states") return "usa"
    else return country
  }

  return {
    ...toRefs(state),
    update,
    toggleView,
    setCountry,
  }
}
