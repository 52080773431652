<template>
  <div v-if="profile.golfLinkNo">
    <h1>Link Handicap</h1>
    <p>You already have a handicap linked to your account.</p>
    <global-button
      :style="{ marginTop: '25px' }"
      :disabled="loading"
      :loading="loading"
      expand="block"
      text="Unlink"
      @click="unlinkHandicap()"
    >
    </global-button>
  </div>
  <div v-else>
    <h1>Select your location</h1>
    <div class="form">
      <ion-row
        class="form-item"
        :key="`country-${option.value}`"
        v-for="option in options"
      >
        <ion-col size="12">
          <ion-checkbox
            color="secondary"
            label-placement="end"
            :checked="form.selectedCountry == option.value"
            :disabled="form.selectedCountry == option.value"
            @ionChange="updateForm('selectedCountry', option.value)"
          >
            <span class="country">{{ option.label }}</span></ion-checkbox
          >
        </ion-col>
      </ion-row>
      <ion-row class="form-item">
        <ion-col size="12">
          <global-button
            :disabled="disabled"
            expand="block"
            text="Continue"
            @click="
              form.selectedCountry == 'row'
                ? changeStep('step4')
                : changeStep('step2')
            "
          >
          </global-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { IonRow, IonCol, IonCheckbox } from "@ionic/vue"
import User from "@/modules/user"
import GlobalButton from "@/components/global/GlobalButton"
import _ from "lodash"
const { profile, update, get } = User()
const { form, updateForm, step, changeStep, dismissModal } = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
  updateForm: {
    type: Function,
    default: () => {},
  },
  step: {
    type: String,
    default: null,
  },
  changeStep: {
    type: Function,
    default: () => {},
  },
})

const disabled = ref(!form.selectedCountry)
const loading = ref(false)
const options = [
  {
    label: "Australia",
    value: "australia",
  },
  {
    label: "England",
    value: "england",
  },
  {
    label: "Scotland",
    value: "scotland",
  },
  {
    label: "Ireland",
    value: "ireland",
  },
  {
    label: "Rest of world",
    value: "row",
  },
]

watch(form, (updated) => {
  disabled.value = !form.selectedCountry
})

const unlinkHandicap = async () => {
  loading.value = true
  profile.value = {
    ..._.cloneDeep(profile.value),
    golfLinkNo: undefined,
  }
  await update({
    golfLinkNo: null,
    gaHandicap: null,
    dotGolf: null,
    clubhouseHandicap: null,
  })
  await get(true)
  loading.value = false
}
</script>

<style lang="scss" scoped>
.country {
  text-transform: capitalize;
  vertical-align: middle;
}
.checkbox-disabled {
  opacity: 1;
}
</style>
