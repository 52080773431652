import { createRouter, createWebHistory } from "@ionic/vue-router"
import Tabs from "../views/TabsView.vue"
import Firebase from "@/modules/thirdparty/firebase"
const routes = [
  {
    path: "/",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "/home",
      },
      {
        name: "home",
        path: "home",
        component: () => import("@/views/tabs/HomeTab.vue"),
      },
      {
        name: "members",
        path: "members",
        component: () => import("@/views/tabs/MembersTab.vue"),
      },
      {
        name: "rounds",
        path: "rounds",
        component: () => import("@/views/tabs/RoundsTab.vue"),
      },
      {
        name: "courses",
        path: "courses",
        component: () => import("@/views/tabs/CoursesTab.vue"),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(async (to, from) => {
  const { setScreenName } = Firebase()
  setScreenName(to.name)
})

export default router
