<template>
  <div class="c-expand c-flex c-scroll">
    <h1>Link your account</h1>
    <p class="light" v-if="!keyboard">
      For the best experience you can link your handicap account. This process
      may take up to 3 minutes.<span v-if="form.selectedCountry == 'australia'"
        ><br /><br />You can also connect your handicap number directly by
        selecting Link Handicap.</span
      >
    </p>
    <div class="form">
      <ion-row class="form-item" v-if="error">
        <ion-col size="12">
          <p class="danger">{{ error }}</p>
        </ion-col>
      </ion-row>
      <ion-row class="form-item" v-if="form.selectedCountry == 'australia'">
        <ion-col size="12">
          <ion-input
            autocomplete="off"
            fill="outline"
            inputmode="email"
            label-placement="floating"
            label="Email"
            mode="md"
            type="email"
            :disabled="loading"
            :value="email"
            @ionInput="email = $event.target.value"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="form-item" v-else>
        <ion-col size="12">
          <ion-input
            autocomplete="off"
            fill="outline"
            inputmode="numeric"
            label-placement="floating"
            label="Membership no."
            mode="md"
            type="number"
            :disabled="loading"
            :value="membershipNo"
            @ionInput="membershipNo = $event.target.value"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="form-item">
        <ion-col size="12">
          <ion-input
            autocomplete="off"
            fill="outline"
            inputmode="text"
            label-placement="floating"
            label="Password"
            mode="md"
            type="password"
            :disabled="loading"
            :value="password"
            @ionInput="password = $event.target.value"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row
        class="form-item ion-align-items-center"
        v-if="form.selectedCountry == 'australia'"
      >
        <ion-col
          class="ion-text-right"
          offset="6"
          size="6"
          @click="changeStep('step3')"
        >
          <p><span class="link">Link Handicap</span></p>
        </ion-col>
      </ion-row>
      <ion-row class="form-item ion-align-items-center">
        <ion-col size="12">
          <global-button
            expand="block"
            :disabled="disabled || loading"
            :loading="loading"
            text="Link account"
            @click="submit"
          ></global-button>
        </ion-col>
      </ion-row>
    </div>
    <ion-row
      class="ion-align-items-center"
      v-if="!profile.clubhouseHandicap && !keyboard"
    >
      <ion-col class="ion-text-center" size="12">
        <p @click="changeStep('step4')">
          I don't have a handicap. <span class="link">Create handicap</span>
        </p>
      </ion-col>
    </ion-row>
  </div>
</template>

<script setup>
import _ from "lodash"
import { reactive, ref, toRefs, watch } from "vue"
import { IonRow, IonCol, IonInput } from "@ionic/vue"
import App from "@/modules/app"
import User from "@/modules/user"
import Handicap from "@/modules/handicap"
import GlobalButton from "@/components/global/GlobalButton"

const props = defineProps({
  nextStep: {
    type: Function,
    default: () => {},
  },
  form: {
    type: Object,
    default: () => {},
  },
  updateForm: {
    type: Function,
    default: () => {},
  },
  step: {
    type: String,
    default: null,
  },
  changeStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const { keyboard } = App()
const { get, update, profile } = User()
const { linkAccount, dotGolfSync } = Handicap()

const error = ref(false)
const loading = ref(false)
const disabled = ref(true)

const link = reactive({
  email: "",
  membershipNo: "",
  password: "",
})

const { email, membershipNo, password } = toRefs(link)

watch(link, (updated) => {
  disabled.value = !(
    (updated.email || updated.membershipNo) &&
    updated.password
  )
})

const submit = async () => {
  loading.value = true
  error.value = null
  let res = await linkAccount({ ...link, country: props.form.selectedCountry })
  if (res.error) error.value = res.error
  else {
    await get(true)
    await props.dismissModal()
    await props.nextStep()
    if (
      ["england", "scotland", "ireland"].includes(props.form.selectedCountry)
    ) {
      await dotGolfSync(link.membershipNo)
      await get(true)
    }
  }
  loading.value = false
}
</script>

<style lang="scss" scoped>
.message {
  font-size: 0.9rem;
}
.link {
  font-size: 0.9rem;
  font-weight: 500;
}
</style>
