import { reactive, toRefs } from "vue"
import User from "@/modules/user"

const turf = require("@turf/turf")

const state = reactive({})

export default function () {
  const convertDistance = (distance) => {
    const { settings } = User()

    return Math.round(
      turf.convertLength(
        distance,
        "yards",
        settings.value.gps.units || "yards"
      ),
      0
    )
  }

  const distanceBetween = (x, y) => {
    const { settings } = User()

    let from = turf.point(x)
    let to = turf.point(y)
    let options = { units: "miles" }

    return Math.round(
      turf.convertLength(
        turf.distance(from, to, options),
        "miles",
        settings.value.gps.units || "yards"
      ),
      0
    )
  }

  const lineToPolygon = (points) => {
    let lines = []
    if (points && points.Shapes && points.Shapes.Shape) {
      points.Shapes.Shape.map((shape) => {
        lines = lines.concat(shape.Points)
      })
    }
    return lines.length ? turf.lineToPolygon(turf.lineString(lines)) : []
  }

  const midpoint = (x, y) => {
    return turf.midpoint(turf.point(x), turf.point(y))
  }

  return {
    ...toRefs(state),

    convertDistance,
    distanceBetween,
    lineToPolygon,
    midpoint,
  }
}
