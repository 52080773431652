import _ from "lodash"
import { reactive, toRefs } from "vue"

import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import App from "@/modules/app"
import Firebase from "@/modules/thirdparty/firebase"
import Handicap from "@/modules/handicap"
import User from "@/modules/user"
import Settings from "@/modules/user/settings"
import { nSQL } from "@nano-sql/core"

const defaultValues = {
  sortPane: false,
  showCustomSort: false,
  loading: false,
  sorting: false,
  editing: null,
  members: [],
  updating: null,
}

const state = reactive({
  ...defaultValues,
})

export default function () {
  const init = async () => {
    const { query } = DB()
    nSQL("tc_members").on("change", (e) => {
      if (state.updating) state.updating.cancel()
      state.updating = _.debounce(async () => {
        state.members = await query({ table: "tc_members" })
        state.updating = null
      }, 500)
      state.updating()
    })
    state.members = await query({ table: "tc_members" })
  }

  const clear = async () => {
    const { deleteAll } = DB()
    await deleteAll({
      table: `tc_members`,
    })
  }

  const add = async ({ golfLinkNo, nickname, order }) => {
    const { api } = API()
    const { upsert } = DB()
    const { logEvents } = Firebase()
    const { profile } = User()
    let res = await api({
      method: "PUT",
      url: `/user/${profile.value.uid}/member`,
      data: {
        golfLinkNo,
        nickname,
        order,
      },
    })
    if (!res.error) {
      await upsert({
        table: "tc_members",
        data: {
          id: res._id,
          golfLinkNo,
          nickname,
          order,
          type: golfLinkNo.length > 10 ? "clubhouse" : "australia",
        },
      })
      logEvents({ name: "add_member" })
    }
    return res
  }

  const edit = async ({ nickname, golfLinkNo }) => {
    const { api } = API()
    const { upsert } = DB()
    const { logEvents } = Firebase()
    const { profile } = User()
    let res = await api({
      method: "POST",
      url: `/user/${profile.value.uid}/member`,
      data: {
        nickname,
        golfLinkNo,
      },
    })
    if (!res.error) {
      await upsert({
        table: "tc_members",
        data: { nickname },
        where: ["golfLinkNo", "=", golfLinkNo],
      })
      logEvents({ name: "edit_member" })
    }
    return res
  }

  const remove = async (golfLinkNo) => {
    const { api } = API()
    const { deleteItem } = DB()
    const { logEvents } = Firebase()
    const { profile } = User()
    let res = await api({
      method: "DELETE",
      url: `/user/${profile.value.uid}/member`,
      query: `golfLinkNo=${golfLinkNo}`,
    })

    if (!res.error) {
      await deleteItem({
        table: "tc_members",
        data: ["golfLinkNo", "=", golfLinkNo],
      })
      logEvents({ name: "remove_member" })
    }
    return res
  }

  const updateOrder = async (newOrder) => {
    const { api } = API()
    const { upsert } = DB()
    const { profile, settings } = User()
    const { update } = Settings()
    let currentMembers = _.cloneDeep(state.members)
    let toUpdate = []

    state.sorting = true
    let me = _.findIndex(newOrder, (o) => o.user)
    if (me > -1)
      await update({
        members: {
          ..._.cloneDeep(settings.value.members),
          order: newOrder[me].order,
        },
      })

    await Promise.all(
      currentMembers.map(async (member) => {
        if (!member.user) {
          let newData = _.find(
            newOrder,
            (o) => o.golfLinkNo == member.golfLinkNo
          )
          if (newData && member.order != newData.order) {
            await upsert({
              table: "tc_members",
              data: { order: newData.order },
              where: ["golfLinkNo", "=", member.golfLinkNo],
            })
            toUpdate.push({
              golfLinkNo: member.golfLinkNo,
              order: newData.order,
            })
          }
        }
      })
    )

    api({
      method: "POST",
      url: `/user/${profile.value.uid}/member`,
      data: {
        toUpdate,
      },
    })
    setTimeout(() => {
      state.sorting = false
    }, 500)
  }

  return {
    ...toRefs(state),
    init,
    clear,
    add,
    edit,
    remove,
    updateOrder,
  }
}
