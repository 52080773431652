<template>
  <ion-page class="c-page c-modal">
    <ion-content
      class="c-content"
      :class="{ [step]: true }"
      :force-overscroll="false"
      :fullscreen="true"
    >
      <div class="c-modal-header" slot="fixed">
        <global-icon
          @action="dismissModal()"
          custom-class="c-modal-close"
          :icon="closeOutline"
        />
      </div>
      <div class="c-modal-content c-flex c-inner-padding">
        <component
          :changeStep="(next) => changeStep(next)"
          :dismissModal="() => dismissModal()"
          :nextStep="() => nextStep()"
          :query="query"
          :step="step"
          :is="stepComponent"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { computed, ref } from "vue"
import { IonPage, IonContent } from "@ionic/vue"
import { closeOutline } from "ionicons/icons"

import Welcome from "@/components/auth/WelcomeStep"
import Login from "@/components/auth/LoginStep"
import Signup from "@/components/auth/SignupStep"
import Forgot from "@/components/auth/ForgotStep"
import Reset from "@/components/auth/ResetStep"
import GlobalIcon from "@/components/global/GlobalIcon"

const { nextStep, query, screen, dismissModal } = defineProps({
  nextStep: {
    type: Function,
    default: () => {},
  },
  query: {
    type: String,
    default: null,
  },
  screen: {
    type: String,
    default: "login",
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const step = ref(screen)

const stepComponent = computed(() => {
  switch (step.value) {
    case "welcome":
      return Welcome
    case "signup":
      return Signup
    case "forgot":
      return Forgot
    case "reset":
      return Reset
    default:
      return Login
  }
})

const changeStep = (next) => {
  step.value = next
}
</script>

<style lang="scss" scoped>
.welcome {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-white);

  .c-modal-header {
    background: var(--ion-color-primary) !important;
    color: var(--ion-color-white);
  }
}
</style>
