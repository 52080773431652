import _ from "lodash"
import { reactive, toRefs } from "vue"
import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import App from "@/modules/app"
import Helpers from "@/modules/helpers"
import Firebase from "@/modules/thirdparty/firebase"
import User from "@/modules/user/index"
import { nSQL } from "@nano-sql/core"
import iGolf from "@/modules/thirdparty/igolf"

const defaultValues = {
  loading: false,
  courses: [],
  selectedClub: null,
  updating: null,
}

const state = reactive({
  ...defaultValues,
})

export default function () {
  const init = async () => {
    nSQL("tc_courses").on("change", (e) => {
      if (state.updating) state.updating.cancel()
      state.updating = _.debounce(async () => {
        await set()
        state.updating = null
      }, 500)
      state.updating()
    })
    await set()
  }

  const set = async () => {
    const { query } = DB()
    let courses = await query({ table: "tc_courses" })
    let daily = courses.map((course) => {
      if (course.type == "iGolf" && !course.teeDetails)
        course = igolfCourse(course)
      return course
    })

    state.courses = daily
  }

  const igolfCourse = (course) => {
    return {
      ...course,
      teeDetails: course.teeColorName || course.teeName,
      scratchRating:
        course.scorecard == 0 ? course.ratingMen : course.ratingWomen,
      slopeRating: course.scorecard == 0 ? course.slopeMen : course.slopeWomen,
      par:
        course.scorecardsList[course.scorecard].parIn +
        course.scorecardsList[course.scorecard].parOut,
    }
  }

  const clear = async () => {
    const { deleteAll } = DB()
    await deleteAll({
      table: `tc_courses`,
    })
  }

  const save = async (key, value) => {
    const { set } = App()
    state[key] = value
    await set({ key, value })
  }

  const add = async (data) => {
    const { api } = API()
    const { upsert } = DB()
    const { openToast } = Helpers()
    const { logEvents } = Firebase()
    const { profile } = User()

    let res = await api({
      method: "PUT",
      url: `/user/${profile.value.uid}/daily`,
      data: data,
    })

    if (!res.error) {
      await upsert({
        table: "tc_courses",
        data: {
          id: res._id,
          ..._.cloneDeep(data),
        },
      })
      logEvents({ name: "add_daily" })
      openToast({ message: "Added course to favourites" })
    } else {
      openToast({ color: "danger", message: "Error adding course" })
    }
    return {}
  }

  const remove = async ({ clubId, courseId }) => {
    const { api } = API()
    const { deleteItem } = DB()
    const { openToast } = Helpers()
    const { logEvents } = Firebase()
    const { profile } = User()

    let res = await api({
      method: "DELETE",
      url: `/user/${profile.value.uid}/daily`,
      query: `clubId=${clubId}&courseId=${courseId}`,
    })

    if (!res.error) {
      await deleteItem({
        table: "tc_courses",
        data: [["courseId", "=", courseId], "AND", ["clubId", "=", clubId]],
      })
      logEvents({ name: "remove_daily" })
      openToast({ color: "danger", message: "Removed course from favourites" })
    } else {
      openToast({ color: "danger", message: "Error removing course" })
    }

    return res
  }

  const pin = async ({ clubId, courseId }) => {
    const { api } = API()
    const { upsert } = DB()
    const { openToast } = Helpers()
    const { logEvents } = Firebase()
    const { profile } = User()

    let course = _.find(
      _.cloneDeep(state.courses),
      (o) => o.courseId == courseId && o.clubId == clubId
    )
    let pinned = !_.clone(course.pinned)

    let res = await api({
      method: "POST",
      url: `/user/${profile.value.uid}/daily`,
      data: {
        clubId,
        courseId,
        pinned,
      },
    })

    if (!res.error) {
      await upsert({
        table: "tc_courses",
        data: { pinned },
        where: [["courseId", "=", courseId], "AND", ["clubId", "=", clubId]],
      })
      logEvents({ name: "pin_daily" })
      if (pinned) openToast({ message: "Pinned course" })
      else openToast({ color: "warning", message: "Unpinned course" })
    } else {
      openToast({ color: "danger", message: "Error pinning course" })
    }

    return res
  }

  const search = async (query) => {
    const { api } = API()
    const { profile } = User()
    let country = "australia"
    if (
      !profile.value.golfLinkNo ||
      profile.value.dotGolf ||
      profile.value.clubhouseHandicap
    )
      country = "all"
    return api({
      method: "GET",
      url: `/course/search`,
      query: `query=${query}&country=${country}`,
    })
  }

  return {
    ...toRefs(state),

    init,
    clear,
    save,
    add,
    remove,
    igolfCourse,
    pin,
    search,
  }
}
