import _ from "lodash"
import { computed, reactive, toRefs } from "vue"
import { Geolocation } from "@capacitor/geolocation"

import Map from "@/modules/helpers/map"
import Scoring from "@/modules/tournament/scoring"

const options = {
  maximumAge: 500,
  timeout: 1000,
  enableHighAccuracy: true,
}

const state = reactive({
  watcher: [],
  location: null,
  errors: 0,
  updating: null,
})

export default function () {
  const start = async () => {
    try {
      await clear()
      let watchIds = _.cloneDeep(state.watcher)
      let watch = await Geolocation.watchPosition(
        options,
        async (location, err) => {
          if (!err && location) state.location = location
          else start()
        }
      )
      if (watch) {
        watchIds.push(watch)
        state.watcher = watchIds
      }
    } catch (err) {
      start()
    }
  }

  const instant = async () => {
    const location = await Geolocation.getCurrentPosition()
    if (location && location.coords)
      state.location = await Geolocation.getCurrentPosition()
  }

  const coordinates = computed(() => {
    const { distanceBetween } = Map()
    const { currentTournament, currentCourse, currentHole, offset } = Scoring()

    let coords = null
    let test = null
    let error = false

    if (state.location && state.location.coords)
      coords = _.cloneDeep([
        state.location.coords.longitude,
        state.location.coords.latitude,
      ])

    if (!currentCourse.value) return coords

    let courseData = _.cloneDeep(
      currentCourse.value.gps[currentHole.value + offset.value]
    )

    if (coords) {
      try {
        test = distanceBetween(coords, [
          courseData.centerLon,
          courseData.centerLat,
        ])
      } catch (err) {
        error = true
      }
    }

    if (test > 1000 || error || !coords) {
      let teeIndex = _.findIndex(
        currentCourse.value.teesList,
        (o) => o.id_courseTeeType == currentTournament.value.rounds[0].tee
      )
      coords = [
        courseData[`teeLon${teeIndex + 1}`],
        courseData[`teeLat${teeIndex + 1}`],
      ]
    }

    return coords
  })

  const clear = async () => {
    state.location = null
    if (state.watcher) {
      state.watcher.map((id) => {
        Geolocation.clearWatch({
          id,
        })
      })
    }
  }

  return {
    ...toRefs(state),
    start,
    clear,
    instant,
    coordinates,
  }
}
