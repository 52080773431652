import _ from "lodash"
import axios from "axios"
import crypto from "crypto-browserify"
import moment from "moment"
import jwt from "jsonwebtoken"

import { reactive, toRefs } from "vue"
import { Browser } from "@capacitor/browser"
import {
  createAnimation,
  modalController,
  popoverController,
  toastController,
} from "@ionic/vue"

import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import App from "@/modules/app"
import Member from "@/modules/user/member"
import Firebase from "@/modules/thirdparty/firebase"
import Society from "@/modules/user/society"
import User from "@/modules/user"

import Modal from "@/components/global/GlobalModal"
import Popover from "@/components/global/GlobalPopover"

const state = reactive({
  modals: {},
  popovers: {},
})

export default function () {
  const authHeader = async (bypass = false) => {
    const { query } = DB()
    let profile = await query({
      table: "tc_users",
      where: ["id", "=", "profile"],
    })
    return jwt.sign(
      {
        bypass,
        uid: profile && profile[0].data.uid,
        timestamp: moment().format("x"),
      },
      secret()
    )
  }

  const headers = async (bypass) => {
    const { query } = DB()
    let auth = await query({ table: "tc_users", where: ["id", "=", "auth"] })

    let header = {
      ClubhouseToken: await authHeader(bypass),
    }
    if (auth && auth[0].data.accessToken)
      header.Authorization = auth[0].data.accessToken
    return header
  }

  const secret = () => {
    let hmac = crypto.createHmac("sha256", process.env.VUE_APP_SECRET)
    return hmac.update(process.env.VUE_APP_SECRET_1).digest("hex")
  }

  const contact = async ({ name, email, message }) => {
    const { api } = API()
    const { metadata } = App()
    return api({
      method: "POST",
      url: "/support/contact",
      bypass: true,
      data: {
        name,
        email,
        message,
        metadata: metadata.value,
      },
    })
  }

  /**
   * Handicap display with E
   */
  const displayHandicap = (handicap) => {
    let display = handicap
    if (handicap != null && !isNaN(handicap) && handicap != "N/A") {
      if (handicap < 0) display = handicap.toFixed(1).replace("-", "+")
      else display = handicap.toFixed(1)
      return display
    } else return ""
  }

  const enterAnimation = (baseEl) => {
    const root = baseEl.shadowRoot

    const wrapperAnimation = createAnimation()
      .addElement(root.querySelector(".modal-wrapper"))
      .keyframes([
        { offset: 0, transform: "translateX(100%)" },
        { offset: 1, transform: "translateX(0)" },
      ])

    return createAnimation()
      .addElement(baseEl)
      .duration(250)
      .addAnimation([wrapperAnimation])
  }

  const leaveAnimation = (baseEl) => {
    const root = baseEl.shadowRoot

    const wrapperAnimation = createAnimation()
      .addElement(root.querySelector(".modal-wrapper"))
      .keyframes([
        { offset: 0, transform: "translateX(100%)" },
        { offset: 1, transform: "translateX(0)" },
      ])

    return createAnimation()
      .addElement(baseEl)
      .duration(250)
      .addAnimation([wrapperAnimation])
      .direction("reverse")
  }

  const openBrowser = async ({ url }) => {
    await Browser.open({ url })
  }

  const openModal = async ({
    breakpoints = [0, 1],
    initialBreakpoint = null,
    enterAnimation = null,
    leaveAnimation = null,
    animated = true,
    backdropBreakpoint = 0,
    backdropDismiss = true,
    showBackdrop = true,
    customClass = "",
    customHeader = false,
    canDismiss = true,
    header = "close",
    page = null,
    heading = null,
    handle = false,
    data = {},
  }) => {
    const { metadata } = App()
    const { logEvents } = Firebase()
    const { currentSociety } = Society()

    if (breakpoints[1] == 0.5 && metadata.value.height < 700)
      breakpoints = [0, 0.75]

    if (page) {
      try {
        const modal = await modalController.create({
          breakpoints,
          initialBreakpoint:
            initialBreakpoint || breakpoints[breakpoints.length - 1],
          backdropBreakpoint,
          backdropDismiss,
          showBackdrop,
          canDismiss,
          handle,
          enterAnimation,
          leaveAnimation,
          animated,
          component: Modal,
          cssClass: customClass,
          componentProps: {
            customHeader,
            data,
            breakpoints,
            handle,
            page,
            header,
            heading,
            setCurrentBreakpoint: (point) => modal.setCurrentBreakpoint(point),
            dismissModal: async () => {
              if (page.__name == "ViewSociety") currentSociety.value = null
              await clearModal(page.__name)
            },
          },
        })
        await setModal(page.__name, modal)
        logEvents({ name: "open_modal", params: { page: page.__name } })
        modal.present()
        return modal
      } catch (err) {
        return err
      }
    }
  }

  const openPopover = async ({ page = null, data = {} }) => {
    const popover = await popoverController.create({
      component: Popover,
      componentProps: {
        page,
        data,
        dismissPopover: async () => {
          await clearPopover(page.__name)
        },
      },
    })
    await setPopover(page.__name, popover)
    await popover.present()
  }

  const setPopover = async (name, popover) => {
    await clearModal(name)
    let currentPopovers = _.cloneDeep(state.popovers)
    currentPopovers[name] = popover
    state.popovers = currentPopovers
  }

  const clearPopover = async (name) => {
    let currentPopovers = _.cloneDeep(state.popovers)
    if (currentPopovers[name]) {
      await currentPopovers[name].dismiss()
      currentPopovers[name] = null
    }
    state.popovers = currentPopovers
  }

  const setModal = async (name, modal) => {
    await clearModal(name)
    let currentModals = _.cloneDeep(state.modals)
    currentModals[name] = modal
    state.modals = currentModals
  }

  const clearModal = async (name) => {
    let currentModals = _.cloneDeep(state.modals)
    if (currentModals[name]) {
      await currentModals[name].dismiss()
      currentModals[name] = null
    }
    state.modals = currentModals
  }

  const openToast = async ({
    color = "secondary",
    message = "",
    duration = 2000,
    position = "top",
  }) => {
    const toast = await toastController.create({
      color,
      message,
      duration,
      position,
    })

    await toast.present()
  }

  const setDate = (date, offset = 0) => {
    let year = moment(date).format("YYYY")
    let month = moment(date).format("M")
    let day = moment(date).format("D")
    let hour = moment(date).format("HH")
    let minute = moment(date).format("mm")
    let d = new Date(year, month - 1, day, hour, minute)
    d.setTime(d.getTime() + offset * (24 * 60 * 60 * 1000))
    return new Date(d)
  }

  const shortCourse = (name) => {
    name = name
      .replaceAll(" and ", " & ")
      .replaceAll(" and ", " & ")
      .replaceAll(" And ", " & ")
      .replaceAll("Country Club", "CC")
      .replaceAll("Golf Course", "")
      .replaceAll("Golf Club", "")
      .replaceAll("GC", "")
      .replaceAll("& Bowls Club", "")
    return name
  }

  const shortTee = (name) => {
    return name && name.replace(", Women", "").replace(", Men", "")
  }

  const formatDate = (date, format) => {
    return moment(date).format(format)
  }

  const validEmail = (e) => {
    const emailRegex = new RegExp(
      /^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
      "gm"
    )
    return emailRegex.test(e)
  }

  const toNumber = (handicap) => {
    if (handicap == "N/A") return handicap
    return parseFloat(handicap.toString().replace("+", "-"))
  }

  const roundedRect = (x, y, w, h, r, tl, tr, bl, br) => {
    var retval
    retval = "M" + (x + r) + "," + y
    retval += "h" + (w - 2 * r)
    if (tr) {
      retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r
    } else {
      retval += "h" + r
      retval += "v" + r
    }
    retval += "v" + (h - 2 * r)
    if (br) {
      retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r
    } else {
      retval += "v" + r
      retval += "h" + -r
    }
    retval += "h" + (2 * r - w)
    if (bl) {
      retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r
    } else {
      retval += "h" + -r
      retval += "v" + -r
    }
    retval += "v" + (2 * r - h)
    if (tl) {
      retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r
    } else {
      retval += "v" + -r
      retval += "h" + r
    }
    retval += "z"
    return retval
  }

  return {
    ...toRefs(state),

    headers,
    secret,
    contact,
    displayHandicap,
    enterAnimation,
    leaveAnimation,
    openBrowser,
    openModal,
    openPopover,
    clearPopover,
    clearModal,
    openToast,
    setDate,
    shortCourse,
    shortTee,
    formatDate,
    validEmail,
    toNumber,
    roundedRect,
  }
}

//
//
//   scoreAvailable(round) {
//     return round.handicappingScore != "N/A" && round.competitionScore != "N/A"
//   },
//
//
//
// }
