import { reactive, toRefs } from "vue"

import { App } from "@capacitor/app"
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"
import { Keyboard } from "@capacitor/keyboard"

// import { PushNotifications } from "@capacitor/push-notifications"

import DB from "@/modules/helpers/db"
import Helpers from "@/modules/helpers"
import Pusher from "@/modules/thirdparty/pusher"
import Scoring from "@/modules/tournament/scoring"
import User from "@/modules/user"

import AuthModal from "@/components/auth/AuthModal"
import SocietyInvites from "@/components/profile/settings/SocietyInvites"

const state = reactive({
  metadata: {},
  loading: true,
  keyboard: false,
  openModals: {},
})

export default function () {
  const init = async (container) => {
    await getMeta(container)
    await DB().init()
    listeners()
    return {}
  }

  const listeners = () => {
    const { connect, disconnect } = Pusher()
    const { openModal } = Helpers()
    const { referralUser, loggedIn } = User()

    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener("keyboardDidShow", () => {
        state.keyboard = true
      })

      Keyboard.addListener("keyboardDidHide", () => {
        state.keyboard = false
      })
    }

    App.addListener("appStateChange", async ({ isActive }) => {
      if (isActive) {
        connect()
        Scoring().init()
      } else {
        disconnect()
      }
    })

    App.addListener("appUrlOpen", (event) => {
      let path = new URL(event.url)
      let params = path.searchParams

      if (params.get("referralCode") && !loggedIn.value) {
        referralUser.value = params.get("referralCode")
        openModal({
          page: AuthModal,
          customHeader: true,
          data: { screen: "signup" },
        })
      } else if (params.get("mode") && params.get("mode") == "resetPassword") {
        openModal({
          page: AuthModal,
          customHeader: true,
          data: { screen: "reset", query: params.get("oobCode") },
        })
      } else if (params.get("view")) {
        let next
        if (params.get("view") == "societyInvites") {
          next = () =>
            openModal({
              page: SocietyInvites,
            })
        }

        if (!loggedIn.value) {
          openModal({
            page: AuthModal,
            data: {
              nextStep: () => next(),
            },
          })
        } else {
          next()
        }
      }
    })
  }

  /**
   * APP METADATA
   */
  const getMeta = async (container) => {
    let metadata = {
      height: container.$el.clientHeight,
      width: container.$el.clientWidth,
    }
    try {
      let app = {}
      if (Capacitor.isNativePlatform()) app = await App.getInfo()
      let device = await Device.getInfo()
      metadata = {
        ...metadata,
        ...app,
        platform: device.platform,
        os: device.operatingSystem,
      }
    } catch (err) {
      metadata = {
        ...metadata,
        error: err.message,
      }
    }
    state.metadata = metadata
  }

  return {
    ...toRefs(state),
    init,
  }
}
