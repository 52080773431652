<template>
  <div>
    <h1>Link Handicap</h1>
    <div class="form">
      <ion-row class="form-item" v-if="error">
        <ion-col size="12">
          <span class="page-error">{{ error }}</span>
        </ion-col>
      </ion-row>
      <ion-row class="form-item">
        <ion-col size="12">
          <ion-input
            autocomplete="off"
            fill="outline"
            inputmode="numeric"
            label-placement="floating"
            label="Handicap no."
            mode="md"
            type="number"
            :value="form.golfLinkNo"
            @ionInput="updateForm('golfLinkNo', $event.target.value)"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="form-item">
        <ion-col size="12">
          <global-button
            expand="block"
            :disabled="disabled || loading"
            :loading="loading"
            text="Link handicap"
            @click="submit"
          ></global-button>
        </ion-col>
      </ion-row>
    </div>
    <ion-row
      class="ion-align-items-center"
      v-if="!profile.clubhouseHandicap && !keyboard"
    >
      <ion-col class="ion-text-center" size="12">
        <p @click="changeStep('step4')">
          I don't have a handicap. <span class="link">Create handicap</span>
        </p>
      </ion-col>
    </ion-row>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { IonRow, IonCol, IonInput } from "@ionic/vue"
import App from "@/modules/app"
import User from "@/modules/user"
import Handicap from "@/modules/handicap"
import GlobalButton from "@/components/global/GlobalButton"
import _ from "lodash"
const { form, updateForm, step, changeStep, dismissModal } = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
  updateForm: {
    type: Function,
    default: () => {},
  },
  step: {
    type: String,
    default: null,
  },
  changeStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const { keyboard } = App()
const { check, getUser } = Handicap()
const { profile, get, update } = User()

const loading = ref(false)
const error = ref(null)
const disabled = ref(true)

watch(form, (updated) => {
  disabled.value = !form.golfLinkNo || form.golfLinkNo.length < 10
})

const submit = async () => {
  loading.value = true
  error.value = null
  let handicapData = await check(form.golfLinkNo, 0, form.selectedCountry)
  if (handicapData.error) error.value = handicapData.error
  else {
    profile.value = {
      ..._.cloneDeep(profile.value),
      golfLinkNo: undefined,
    }
    await update({
      gaHandicap: null,
      clubhouseHandicap: null,
      dotGolf: null,
      golfLinkNo: form.golfLinkNo,
      country: form.selectedCountry,
    })
    await get(true)
    dismissModal()
  }
  loading.value = false
}
</script>

<style lang="scss" scoped>
.link {
  font-size: 0.9rem;
  font-weight: 500;
}
</style>
