<template>
  <ion-page class="c-page c-modal">
    <ion-content class="c-content" :force-overscroll="false" :fullscreen="true">
      <div class="c-modal-header" slot="fixed">
        <global-icon
          @action="changeStep(step == 'step3' ? 'step2' : 'step1')"
          custom-class="c-modal-back"
          :icon="arrowBackOutline"
          v-if="step != 'step1'"
        />
        <global-icon
          @action="dismissModal()"
          custom-class="c-modal-close"
          :icon="closeOutline"
        />
      </div>
      <div class="c-modal-content c-flex c-inner-padding">
        <component
          :form="form"
          :updateForm="updateForm"
          :changeStep="(next) => changeStep(next)"
          :dismissModal="() => dismissModal()"
          :nextStep="() => nextStep()"
          :step="step"
          :is="stepComponent"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { computed, reactive, ref, toRefs } from "vue"
import { IonPage, IonContent } from "@ionic/vue"
import { arrowBackOutline, closeOutline } from "ionicons/icons"

import User from "@/modules/user"

import Step1 from "@/components/setup/Step1Modal"
import Step2 from "@/components/setup/Step2Modal"
import Step3 from "@/components/setup/Step3Modal"
import Step4 from "@/components/setup/Step4Modal"
import GlobalIcon from "@/components/global/GlobalIcon"

const props = defineProps({
  nextStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const { userError } = User()

const step = ref("step1")

const form = reactive({
  selectedCountry: null,
  golfLinkNo: null,
  previousHandicap: 54,
})

const { selectedCountry } = toRefs(form)

const stepComponent = computed(() => {
  switch (step.value) {
    case "step1":
      return Step1
    case "step2":
      return Step2
    case "step3":
      return Step3
    case "step4":
      return Step4
    default:
      return Step1
  }
})

const changeStep = (next) => {
  step.value = next
}

const updateForm = (key, val) => {
  form[key] = val
}
</script>

<style lang="scss"></style>
