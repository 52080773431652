import _ from "lodash"
import { reactive, toRefs } from "vue"

import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import App from "@/modules/app"
import User from "@/modules/user"
import Pusher from "@/modules/thirdparty/pusher"
import Scoring from "@/modules/tournament/scoring"
import { nSQL } from "@nano-sql/core"

const defaultValues = {
  tournaments: [],
  loading: false,
  updating: null,
}

const state = reactive(_.cloneDeep(defaultValues))

export default function () {
  const init = async () => {
    const { query } = DB()
    nSQL("tc_tournaments").on("change", (e) => {
      if (state.updating) state.updating.cancel()
      state.updating = _.debounce(async () => {
        state.tournaments = await query({ table: "tc_tournaments" })
        state.updating = null
      }, 500)
      state.updating()
    })
    state.tournaments = await query({ table: "tc_tournaments" })
    Scoring().init()
  }

  const create = async ({
    id_course,
    courseName,
    players,
    scoring,
    holes,
    startingHole,
    gps,
    tee,
    scorecard,
    date,
  }) => {
    const { api } = API()
    const { upsert } = DB()
    const { profile } = User()

    tee = tee.replace("-womens", "")

    let res = await api({
      method: "PUT",
      url: `/user/${profile.value.uid}/tournament`,
      data: {
        players,
        settings: {
          gps,
        },
        rounds: [
          {
            id_course,
            courseName,
            scoring,
            holes,
            startingHole,
            tee,
            scorecard,
            date,
          },
        ],
      },
    })
    if (!res.error) {
      await upsert({
        table: "tc_tournaments",
        data: {
          id: res._id,
          ...res,
        },
      })
    }
    return res
  }

  const get = async () => {
    const { api } = App()
    const { profile } = User()
    state.loading = true
    let res = await api({
      method: "GET",
      url: `/user/${profile.value.uid}/tournament`,
    })
    if (!res.error) await save("tournaments", res)
    state.loading = false
    return {}
  }

  const remove = async () => {
    const { api } = API()
    const { deleteItem } = DB()
    const { currentTournament, closeTournament } = Scoring()
    await api({
      method: "POST",
      url: `/tournament/${currentTournament.value.hash}/remove`,
      data: {
        hash: currentTournament.value.hash,
      },
    })
    await deleteItem({
      table: "tc_tournaments",
      data: ["hash", "=", currentTournament.value.hash],
    })
    await closeTournament()
    return {}
  }

  const save = async (hash, data) => {
    const { upsert } = DB()
    await upsert({
      table: "tc_tournaments",
      where: ["hash", "=", hash],
      data,
    })
  }

  const clear = async () => {
    const { deleteAll } = DB()
    await deleteAll({
      table: `tc_tournaments`,
    })
  }

  const updatePlayers = async (players) => {
    const { api } = API()
    const { socketId } = Pusher()
    const { currentTournament } = Scoring()
    const { profile } = User()
    await api({
      method: "POST",
      url: `/tournament/${currentTournament.value.hash}/players`,
      data: {
        uid: profile.value.uid,
        scorecards: _.map(players, (o) => ({
          _id: o._id,
          user: o.user,
          nickname: o.nickname,
          golfLinkNo: o.golfLinkNo,
          handicap: o.handicap,
          type: o.type,
        })),
        socketId: socketId.value,
      },
    })
    return {}
  }

  const updateStatus = async (status) => {
    const { api } = App()
    const { currentTournament, currentRound, rounds, loadingTournament } =
      Scoring()
    const { profile } = User()
    loadingTournament.value = true
    await api({
      method: "POST",
      url: `/tournament/${currentTournament.value.hash}`,
      data: {
        uid: profile.value.uid,
        status,
      },
    })

    if (currentTournament.value.user == profile.value.uid) {
      let tournament = _.cloneDeep(currentTournament.value)
      let round = _.cloneDeep(currentRound.value)
      tournament.status = status
      round.status = status
      Scoring().save("currentTournament", tournament)
      Scoring().save("currentRound", round)
    }

    let allRounds = _.cloneDeep(rounds.value)
    allRounds.map((o) => {
      if (o.scorer == profile.value.uid) o.status = status
    })

    Scoring().save("rounds", allRounds)

    loadingTournament.value = false
    return {}
  }

  return {
    ...toRefs(state),
    init,
    get,
    save,
    clear,
    create,
    remove,
    updatePlayers,
    updateStatus,
  }
}
