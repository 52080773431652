<template>
  <div>
    <h1>Clubhouse Handicap</h1>
    <p class="light">
      You can create a Clubhouse Handicap to start tracking your handicap. The
      handicap is calculated in accordance with WHS.
    </p>
    <div class="form">
      <ion-row class="form-item" v-if="error">
        <ion-col size="12">
          <span class="page-error">{{ error }}</span>
        </ion-col>
      </ion-row>
      <ion-row class="form-item">
        <ion-col size="12">
          <ion-input
            autocomplete="off"
            fill="outline"
            inputmode="numeric"
            label-placement="floating"
            label="Previous handicap"
            mode="md"
            type="number"
            :value="form.previousHandicap"
            @ionInput="updateForm('previousHandicap', $event.target.value)"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="form-item">
        <ion-col size="12">
          <global-button
            expand="block"
            :disabled="disabled || loading"
            :loading="loading"
            text="Create handicap"
            @click="submit"
          ></global-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash"
import { computed, reactive, ref, toRefs, watch, watchEffect } from "vue"
import { IonRow, IonCol, IonInput } from "@ionic/vue"
import User from "@/modules/user"
import Handicap from "@/modules/handicap"
import GlobalButton from "@/components/global/GlobalButton"

const props = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
  updateForm: {
    type: Function,
    default: () => {},
  },
  step: {
    type: String,
    default: null,
  },
  changeStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const { check, create } = Handicap()
const { profile, get, updateDetails } = User()

const loading = ref(false)
const error = ref(null)
const disabled = ref(false)

const submit = async () => {
  loading.value = true
  error.value = null
  let createHandicap = await create({
    previousHandicap: props.form.previousHandicap,
  })
  if (createHandicap.error) error.value = createHandicap.error
  else {
    await get(true)
    props.dismissModal()
  }
  loading.value = false
}

const validHandicap = computed(() => {
  if (!props.form.previousHandicap) return false
  if (props.form.previousHandicap) {
    let test = _.clone(props.form.previousHandicap).toString()
    if (test.slice(0, 1) == "+") test = test.slice(1, test.length)
    if (isNaN(parseFloat(test)) || test < 0 || test > 54 || test.includes("e"))
      return false
  }
  return true
})

watchEffect(() => {
  disabled.value = !props.form.previousHandicap || !validHandicap.value
})

watchEffect(() => {
  if (
    props.form.previousHandicap &&
    props.form.previousHandicap.length > 1 &&
    props.form.previousHandicap.slice(0, 1) == "."
  )
    props.updateForm("previousHandicap", `0${props.form.previousHandicap}`)
})
</script>

<style lang="scss" scoped>
.message {
  font-size: 0.9rem;
}
.link {
  font-size: 0.9rem;
  font-weight: 500;
}
</style>
