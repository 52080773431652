import { Capacitor } from "@capacitor/core"

import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { indexedDBLocalPersistence, initializeAuth } from "firebase/auth"

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"

import User from "@/modules/user"

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API,
  authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MID,
}

export default function () {
  const initialize = async () => {
    const app = initializeApp(config)
    if (Capacitor.isNativePlatform()) {
      initializeAuth(app, {
        persistence: indexedDBLocalPersistence,
      })
      await FirebaseAnalytics.enable()
    } else {
      getAnalytics(app)
    }
  }

  const setFile = (raw, folder, name) => {
    const { profile } = User()
    let file = raw.files[0]
    let fileFormat = file.name.split(".").slice(-1)[0]

    const storage = getStorage()
    return ref(
      storage,
      `/uploads/${profile.value.fid}/${folder}/${name}.${fileFormat}`
    )
  }

  const getFile = (folder, name, fileFormat = "png") => {
    const storage = getStorage()
    const { profile } = User()

    return getDownloadURL(
      ref(
        storage,
        `/uploads/${profile.value.fid}/${folder}/${name}.${fileFormat}`
      )
    )
      .then((url) => ({
        url,
      }))
      .catch((error) => ({
        error,
      }))
  }

  const setScreenName = async (screenName) => {
    if (Capacitor.isNativePlatform())
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: screenName,
        },
      })
  }

  const setUserIds = async (userId) => {
    if (Capacitor.isNativePlatform())
      await FirebaseAnalytics.setUserId({
        userId,
      })
    else setUserId(getAnalytics(), userId)
  }

  const logEvents = async ({ name, params = {} }) => {
    let event = {
      name,
      params,
    }
    if (Capacitor.isNativePlatform()) await FirebaseAnalytics.logEvent(event)
    else logEvent(getAnalytics(), event)
  }

  const reset = async () => {
    if (Capacitor.isNativePlatform()) await FirebaseAnalytics.reset()
  }

  return {
    initialize,
    setFile,
    getFile,
    setUserIds,
    setScreenName,
    logEvents,
    reset,
  }
}
