<template>
  <div class="form" :class="{ 'c-expand': !keyboard }">
    <h1>Create account</h1>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="off"
          fill="outline"
          inputmode="text"
          label-placement="floating"
          label="Name"
          mode="md"
          type="text"
          :disabled="loading"
          :value="name"
          @ionInput="name = $event.target.value"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="off"
          fill="outline"
          inputmode="text"
          label-placement="floating"
          label="Email"
          mode="md"
          type="email"
          :disabled="loading"
          :value="email"
          @ionInput="email = $event.target.value"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <div class="password">
          <ion-input
            autocomplete="off"
            fill="outline"
            inputmode="password"
            label-placement="floating"
            label="Password"
            mode="md"
            :type="showPassword ? 'text' : 'password'"
            :disabled="loading"
            :value="password"
            @ionInput="password = $event.target.value"
          ></ion-input>
          <ion-icon
            class="show"
            :icon="showPassword ? eyeOffOutline : eyeOutline"
            @click="showPassword = !showPassword"
          ></ion-icon>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <ion-input
          autocomplete="off"
          fill="outline"
          inputmode="text"
          label-placement="floating"
          label="Referral code"
          mode="md"
          type="text"
          :disabled="loading"
          :value="referral"
          @ionInput="referral = $event.target.value"
        ></ion-input>
      </ion-col>
      <ion-col size="12">
        <p class="light helper-text">
          If you were referred by a friend, enter the referral code here.
        </p>
      </ion-col>
    </ion-row>
    <ion-row class="form-item">
      <ion-col size="12">
        <global-button
          expand="block"
          :disabled="disabled || loading"
          :loading="loading"
          text="Sign up"
          @click="submit"
        ></global-button>
      </ion-col>
    </ion-row>
  </div>
  <ion-row class="ion-align-items-center c-modal-footer" v-if="!keyboard">
    <ion-col class="ion-text-center" size="12">
      <p class="light">
        Already have an account?
        <span class="link" @click="changeStep('login')">Login</span>
      </p>
    </ion-col>
  </ion-row>
</template>

<script setup>
import _ from "lodash"
import { reactive, ref, toRefs, watch } from "vue"
import { IonRow, IonCol, IonInput, IonIcon } from "@ionic/vue"
import { eyeOutline, eyeOffOutline } from "ionicons/icons"

import App from "@/modules/app"
import Helpers from "@/modules/helpers"
import User from "@/modules/user"

import GlobalButton from "@/components/global/GlobalButton"

const { query, nextStep, step, changeStep, dismissModal } = defineProps({
  query: {
    type: String,
    default: null,
  },
  nextStep: {
    type: Function,
    default: () => {},
  },
  step: {
    type: String,
    default: null,
  },
  changeStep: {
    type: Function,
    default: () => {},
  },
  dismissModal: {
    type: Function,
    default: () => {},
  },
})

const { keyboard } = App()
const { openModal, openToast, validEmail } = Helpers()
const { referralUser, register, loadingUser } = User()

const loading = ref(false)
const error = ref(false)
const disabled = ref(true)
const showPassword = ref(false)

const form = reactive({
  name: "",
  email: "",
  password: "",
  referral: _.clone(referralUser.value) || "",
})

const { name, email, password, referral } = toRefs(form)

watch(form, (updated) => {
  disabled.value = !(
    updated.name &&
    updated.name.length >= 3 &&
    updated.email &&
    validEmail(updated.email) &&
    updated.email.length >= 5 &&
    updated.password &&
    updated.password.length >= 6
  )
})

const submit = async () => {
  loading.value = true
  error.value = null
  showPassword.value = false
  let res = await register(form)
  if (res.error) {
    openToast({ color: "danger", message: res.error || "Tefewf" })
    loading.value = false
  } else {
    let tries = 0
    while (tries <= 8) {
      await new Promise((res) => setTimeout(res, 2500))
      if (!loadingUser.value) tries = 9
      else tries++
    }
    await dismissModal()
    await nextStep()
  }
}
</script>
