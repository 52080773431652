import _ from "lodash"
import { computed, reactive, toRefs } from "vue"
import { nSQL } from "@nano-sql/core"

import router from "@/router"

import API from "@/modules/helpers/api"
import DB from "@/modules/helpers/db"
import Helpers from "@/modules/helpers"
import Holes from "@/modules/tournament/gps/holes"
import iGolf from "@/modules/thirdparty/igolf"
import Location from "@/modules/tournament/gps/location"
import Handicap from "@/modules/handicap"
import Pusher from "@/modules/thirdparty/pusher"
import Tournament from "@/modules/tournament/index"
import User from "@/modules/user"

const defaultValues = {
  tries: 0,
  connecting: false,
  loadingTournament: false,
  currentTournament: null,
  currentRound: null,
  currentCourse: null,
  rounds: [],
  currentHole: 1,
  updating: null,
  updatingRounds: null,
  updatingScores: {},
  position: "bottom",
}

const state = reactive(_.cloneDeep(defaultValues))

const attributes = [
  "currentTournament",
  "currentRound",
  "currentCourse",
  "rounds",
  "currentHole",
]

export default function () {
  const init = async () => {
    const { start } = Location()

    nSQL("tc_tournament").on("change", (e) => {
      if (state.updating) state.updating.cancel()
      state.updating = _.debounce(async () => {
        await saveAttributes()
        state.updating = null
      }, 500)
      state.updating()
    })

    nSQL("tc_tournament_rounds").on("change", (e) => {
      if (state.updatingRounds) state.updatingRounds.cancel()
      state.updatingRounds = _.debounce(async () => {
        await saveRounds()
        state.updatingRounds = null
      }, 500)
      state.updatingRounds()
    })

    await saveAttributes()
    await saveRounds()

    if (state.currentTournament) {
      if (router.currentRoute.value.fullPath != "/tabs/rounds")
        await router.push("/tabs/rounds")
      await latestScores()
      start()
      eventListener()
    }
  }

  const saveAttributes = async () => {
    const { query } = DB()
    let tournamentData = await query({ table: "tc_tournament" })
    if (!tournamentData.length)
      await Promise.all(
        attributes.map((id) => {
          state[id] = defaultValues[id]
        })
      )
    else {
      tournamentData.map((data) => {
        if (data.data) {
          if (data.id == "currentHole") state[data.id] = data.data.current
          else if (
            ["currentTournament", "currentRound", "currentCourse"].includes(
              data.id
            )
          )
            state[data.id] = data.data
        }
      })
    }
    state.loadingTournament = false
  }

  const saveRounds = async () => {
    const { query } = DB()
    let tournamentRounds = await query({ table: "tc_tournament_rounds" })
    let rounds = []
    tournamentRounds.map((round) => {
      if (state.updatingScores[round.id])
        rounds.push(_.cloneDeep(_.find(state.rounds, (o) => o._id == round.id)))
      else rounds.push(round.data)
    })
    state.rounds = rounds
    state.loadingTournament = false
  }

  const clear = async () => {
    const { deleteAll } = DB()
    await deleteAll({
      table: `tc_tournament`,
    })
    await deleteAll({
      table: `tc_tournament_rounds`,
    })
  }

  const joinTournament = async ({ code }) => {
    const { api } = API()
    let res = await api({
      method: "POST",
      url: `/tournament/code`,
      data: {
        code,
      },
    })
    if (res.error) return res
    selectTournament({
      hash: res.hash,
      spectator: code.slice(0, 1) == "V" || code.slice(0, 1) == "v",
    })
    return {}
  }

  const selectTournament = async ({ hash, spectator = false }) => {
    const { api } = API()
    const { upsert } = DB()
    const { openToast } = Helpers()
    const {
      formatPoints,
      teesList,
      scorecardsList,
      courseGPS,
      courseGPSVector,
    } = iGolf()
    const { start } = Location()

    state.loadingTournament = true

    let res = await api({
      method: "GET",
      url: `/tournament/${hash}`,
    })

    if (res.error) {
      state.loadingTournament = false
      return openToast({
        color: "danger",
        message: res.error,
      })
    }
    if (
      (!res.course.teesList ||
        !res.course.scorecardsList ||
        !res.course.gps ||
        !res.course.gpsVector) &&
      state.tries <= 1
    ) {
      await Promise.all([
        teesList(res.course.id_course),
        scorecardsList(res.course.id_course),
        courseGPS(res.course.id_course),
        courseGPSVector(res.course.id_course),
      ])
      state.tries++
      return selectTournament({ hash })
    }

    state.tries = 0

    res.tournament.spectator = spectator
    if (res.course.gpsVector) formatPoints(res.course.gpsVector)

    let currentHole = _.cloneDeep(state.currentHole)
    if (!state.currentHole) currentHole = res.tournament.rounds[0].startingHole

    await Promise.all(
      attributes.map(async (id) => {
        let data

        if (id == "rounds") {
          await Promise.all(
            res.rounds.map(async (player) => {
              await upsert({
                table: "tc_tournament_rounds",
                data: {
                  id: player._id,
                  data: player,
                },
              })
            })
          )
        } else {
          if (id == "currentTournament") data = res.tournament
          else if (id == "currentRound") data = res.tournament.rounds[0]
          else if (id == "currentCourse") data = res.course
          else if (id == "currentHole")
            data = {
              current:
                currentHole >= res.tournament.rounds[0].holes
                  ? res.tournament.rounds[0].holes
                  : currentHole,
            }

          await upsert({
            table: "tc_tournament",
            data: {
              id,
              data,
            },
          })
        }
      })
    )

    await start()
    await eventListener()
  }

  const latestScores = async (loading = false) => {
    const { api } = API()
    const { upsert } = DB()

    if (loading) state.loadingTournament = true
    let rounds = _.cloneDeep(state.rounds)
    let data = await api({
      method: "GET",
      url: `/tournament/${state.currentTournament.hash}/players`,
    })
    data.map(async (player) => {
      let index = _.findIndex(rounds, (o) => o._id == player._id)
      let playerData
      if (index > -1) playerData = { ...rounds[index], ...player }
      else playerData = player

      await upsert({
        table: "tc_tournament_rounds",
        data: {
          id: player._id,
          data: playerData,
        },
      })
    })
    return {}
  }

  const changeHole = async (change) => {
    const { upsert } = DB()
    let startingHole = _.clone(state.currentTournament.rounds[0].startingHole)
    let holes = _.clone(state.currentTournament.rounds[0].holes)

    if (holes == 9 && startingHole == 10) holes = 18

    let current = _.clone(state.currentHole)
    current += change
    if (current > holes)
      current = state.currentTournament.rounds[0].holes == 18 ? 1 : startingHole
    else if (
      current < 1 ||
      (state.currentTournament.rounds[0].holes == 9 && current < startingHole)
    )
      current = holes
    setTimeout(async () => {
      state.currentHole = current
      await upsert({
        table: "tc_tournament",
        data: {
          id: "currentHole",
          data: { current },
        },
      })
      if (mode.value == "gps") Holes().goto()
    }, 250)
  }

  const updateScores = async (index, label, hole, currentValue) => {
    const { api } = API()
    const { upsert } = DB()
    const { socketId } = Pusher()

    if (state.currentTournament) {
      let roundData = _.cloneDeep(state.rounds)
      let id = _.clone(roundData[index]._id)
      roundData[index].scoring[label][hole - 1] = currentValue
      state.rounds = roundData
      eventListener()
      if (state.updatingScores[id]) state.updatingScores[id].cancel()
      state.updatingScores[id] = _.debounce(async () => {
        if (state.currentTournament) {
          await api({
            method: "POST",
            url: `/tournament/${state.currentTournament.hash}/score`,
            data: {
              socketId: socketId.value,
              scorecard: state.rounds[index],
            },
          })
          await upsert({
            table: "tc_tournament_rounds",
            data: {
              id: state.rounds[index]._id,
              data: state.rounds[index],
            },
          })
        }
        state.updatingScores[id] = null
        delete state.updatingScores[id]
      }, 5000)
      state.updatingScores[id]()
    }
  }

  const eventListener = async () => {
    const { upsert, deleteItem } = DB()
    const { status, pusher, connect } = Pusher()

    let tries = 0
    while (tries <= 10) {
      if (status.value) tries = 11
      else tries++
      await new Promise((res) => setTimeout(res, 1000))
    }

    if (!status.value) {
      await connect()
      return eventListener()
    }

    if (state.currentTournament) {
      let channel = pusher.value.subscribe(
        `private-encrypted-event-${state.currentTournament.hash}`
      )

      channel.bind("score-update", async (data) => {
        let rounds = _.cloneDeep(state.rounds)
        data.map(async (player) => {
          let index = _.findIndex(rounds, (o) => o._id == player._id)
          let playerData
          if (index > -1) playerData = { ...rounds[index], ...player }
          else playerData = player

          await upsert({
            table: "tc_tournament_rounds",
            data: {
              id: player._id,
              data: playerData,
            },
          })
        })
      })

      channel.bind("score-remove", async (data) => {
        let rounds = _.cloneDeep(state.rounds)
        data.map(async (player) => {
          _.remove(rounds, (o) => o._id == player)
          await deleteItem({
            table: "tc_tournament_rounds",
            data: ["id", "=", player],
          })
        })
      })
    }
    return {}
  }

  const updateStatus = async () => {
    const { api } = API()
    const { save } = Tournament()

    state.loadingTournament = true
    let status = completed.value ? null : "complete"
    await api({
      method: "POST",
      url: `/tournament/${state.currentTournament.hash}/status`,
      data: {
        status: status,
      },
    })
    await selectTournament({ hash: state.currentTournament.hash })
    await save(state.currentTournament.hash, { status })
    state.loadingTournament = false
    return {}
  }

  const closeTournament = async () => {
    const { pusher, status } = Pusher()
    state.loadingTournament = true
    if (status.value && state.currentTournament)
      pusher.value.unsubscribe(
        `private-encrypted-event-${state.currentTournament.hash}`
      )
    await Location().clear()
    await clear()
  }

  const mode = computed(() => {
    const { validMembership } = User()
    if (validMembership.value) return "gps"
    return "scoring"
  })

  const handicapping = computed(() => {
    let data = {}

    let rounds = _.cloneDeep(state.rounds)
    let currentScorecards = _.cloneDeep(state.currentCourse.scorecardsList)
    let currentTees = _.cloneDeep(state.currentCourse.teesList)

    rounds.map((round, index) => {
      data[index] = {
        ...dailyHandicap(
          _.find(currentTees, (o) => o.id_courseTeeType == round.tee),
          currentScorecards[round.scorecard],
          state.currentRound.holes,
          round.handicap
        ),
        handicap: round.handicap,
      }
    })
    return data
  })

  const tees = computed(() => {
    if (state.currentCourse && state.currentTournament.rounds)
      return _.find(
        state.currentCourse.teesList,
        (o) => o.id_courseTeeType == state.currentTournament.rounds[0].tee
      )

    return {}
  })

  const scorecard = computed(() => {
    if (state.currentCourse && state.currentTournament.rounds) {
      let data = _.cloneDeep(
        state.currentCourse.scorecardsList[
          state.currentTournament.rounds[0].scorecard
        ]
      )

      let par = data.parHole
      let hcp = data.hcpHole
      let yds = _.cloneDeep(tees.value.ydsHole)

      if (
        _.sum(data.parHole.slice(9, 18)) == 0 &&
        state.currentRound.holes == 18
      ) {
        par = par.slice(0, 9).concat(par.slice(0, 9))
        let adjustedHcp = []
        hcp
          .slice(0, 9)
          .concat(hcp.slice(0, 9))
          .map((strokeIndex, index) => {
            if (index > 8) adjustedHcp.push(strokeIndex * 2)
            else adjustedHcp.push(strokeIndex * 2 - 1)
          })
        hcp = adjustedHcp
        yds = yds.slice(0, 9).concat(yds.slice(0, 9))
      }

      return {
        par,
        hcp,
        yds,
        parTotal: _.sum(par),
      }
    }
    return {}
  })

  const offset = computed(() => {
    if (
      state.currentCourse &&
      state.currentCourse.gps &&
      state.currentCourse.gps.length == 9 &&
      state.currentHole > 9
    )
      return -10
    return -1
  })

  const userIndex = computed(() => {
    const { profile } = User()
    return _.findIndex(state.rounds, (o) => o.user == profile.value.uid)
  })

  const holeVector = computed(() => {
    if (state.currentCourse)
      return _.cloneDeep(
        state.currentCourse.gpsVector.Holes.Hole[
          state.currentHole + offset.value
        ]
      )
    else return {}
  })

  const holeGPS = computed(() => {
    if (state.currentCourse && state.currentCourse.gps)
      return _.cloneDeep(
        state.currentCourse.gps[state.currentHole + offset.value]
      )
    else return {}
  })

  const leaderboard = computed(() => {
    let data = []
    let rounds = _.cloneDeep(state.rounds)

    let best = []
    let carryover = 0

    if (state.currentRound.scoring.includes("skins")) {
      for (let hole = 0; hole < state.currentRound.holes; hole++) {
        let points = hole <= 5 ? 1 : hole <= 11 ? 2 : 3
        best.push({
          score: null,
          player: null,
          points: null,
        })
        rounds.map((round, index) => {
          let score = round.scoring.score[hole]
          if (score) {
            let par = scorecard.value.par[hole]
            let shots = handicapping.value[index].shots[hole]

            let adjustedPar = shots + par
            let adjustedScore = score - adjustedPar
            if (!best[hole].score || adjustedScore < best[hole].score) {
              best[hole].score = adjustedScore
              best[hole].player = round._id
              best[hole].points = points + carryover
            } else if (adjustedScore == best[hole].score) {
              best[hole].score = adjustedScore
              best[hole].player = null
              best[hole].points = null
              carryover += points
            }
          }
        })
        if (best[hole].player) carryover = 0
      }
    }

    let currentScorecards = _.cloneDeep(state.currentCourse.scorecardsList)

    rounds.map((round, index) => {
      let skins = 0
      let points = []
      let handicap = []
      let totalPar = 0
      let parPlayed = []
      let playedShots = []
      let adjustedScores = []
      let holesPlayed = 0

      for (let hole = 0; hole < state.currentRound.holes; hole++) {
        let score = round.scoring.score[hole]
        let par = currentScorecards[round.scorecard].parHole[hole]
        let shots = handicapping.value[index].shots[hole]

        let adjustedPar = shots + par
        let adjustedScore = score - adjustedPar

        if (score) holesPlayed++

        totalPar += par
        handicap.push(shots)
        points.push(score ? stablefordScore(adjustedScore) : null)
        parPlayed.push(score ? par : 0)
        playedShots.push(score ? shots : 0)

        if (state.currentRound.scoring == "stableford") {
          let adjusted = score
          if (adjusted - shots - par >= 2) adjusted = par + shots + 2
          adjustedScores.push(score ? adjusted : 0)
        }

        if (state.currentRound.scoring.includes("skins")) {
          if (best[hole].player == round._id) skins += best[hole].points
        }
      }

      let pros = 0

      if (
        state.currentTournament.hash == 2767339889929 &&
        (round.nickname || round.name).includes(" - Pro")
      )
        pros = 5

      let score = {
        holeByHole: round.scoring.score,
        front9: _.sum(round.scoring.score.slice(0, 9)),
        back9: _.sum(round.scoring.score.slice(9, 18)),
        total: _.sum(round.scoring.score) + pros,
        adjusted: _.sum(round.scoring.score) - _.sum(playedShots),
        adjustedStableford: _.sum(adjustedScores),
      }

      data.push({
        _id: round._id,
        nickname: round.nickname || round.name,
        scorer: round.scorer,
        holesPlayed,
        score,
        handicap: {
          daily: handicapping.value[index],
          holeByHole: handicap,
          current: round.handicap,
        },
        skins,
        stableford: {
          holeByHole: points,
          front9: _.sum(points.slice(0, 9)),
          back9: _.sum(points.slice(9, 18)),
          total: _.sum(points),
        },
        toPar: {
          front9: parseScore(score.front9 - _.sum(parPlayed.slice(0, 9))),
          back9: parseScore(score.back9 - _.sum(parPlayed.slice(9, 18))),
          total: parseScore(score.total - _.sum(parPlayed)),
          adjusted: parseScore(
            score.total - _.sum(playedShots) - _.sum(parPlayed)
          ),
          adjustedRaw: score.total - _.sum(playedShots) - _.sum(parPlayed),
          adjustedStableford: parseScore(
            score.adjustedStableford - _.sum(parPlayed)
          ),
        },
      })
    })
    return data
  })

  const completed = computed(() => {
    const { profile } = User()
    let complete = false
    if (state.currentTournament && state.currentTournament.status == "complete")
      return true
    state.rounds.map((round) => {
      if (round.scorer == profile.value.uid && round.status == "complete")
        complete = true
    })
    return complete
  })

  const finished = computed(() => {
    let complete = false
    if (state.currentRound && scoring.value.length) {
      let holes = state.currentRound.holes
      let allPlayers = true
      if (admin.value) {
        state.rounds.map((score) => {
          let holesPlayed = 0
          score.scoring.score.map((hole) => {
            if (hole != null) holesPlayed++
          })
          if (holesPlayed != holes) allPlayers = false
        })
      } else
        scoring.value.map((score) => {
          let holesPlayed = 0
          score.scoring.score.map((hole) => {
            if (hole != null) holesPlayed++
          })
          if (holesPlayed != holes) allPlayers = false
        })
      return allPlayers
    }
    return complete
  })

  const admin = computed(() => {
    const { profile } = User()
    return profile.value.uid == state.currentTournament.user
  })

  const scoring = computed(() => {
    const { profile } = User()
    return _.filter(state.rounds, (o) => o.scorer == profile.value.uid)
  })

  return {
    ...toRefs(state),
    init,
    clear,
    joinTournament,
    selectTournament,
    closeTournament,
    changeHole,
    latestScores,
    updateScores,
    updateStatus,

    admin,
    mode,
    completed,
    finished,
    tees,
    scorecard,
    offset,

    userIndex,
    scoring,
    handicapping,
    leaderboard,

    holeGPS,
    holeVector,
  }
}

const dailyHandicap = (tees, scorecard, holes, handicap) => {
  const { toNumber } = Helpers()

  if (tees) console.log(tees.slopeMen)

  let totalPar = _.sum(scorecard.parHole)
  let slope = tees ? (tees.slopeMen != 0 ? tees.slopeMen : tees.slopeWomen) : 0
  let scratch = tees
    ? tees.ratingMen != 0
      ? tees.ratingMen
      : tees.ratingWomen
    : 0

  if (slope == 0) slope = 113
  if (scratch == 0) scratch = totalPar

  if (holes == 9) handicap = (handicap || 0) / 2

  let shots = (toNumber(handicap) * (slope / 113) + (scratch - totalPar)) * 0.93

  let totalShots = shots
  let shotsArray = _.fill(Array(18), 0)

  if (totalShots > 0) {
    while (totalShots > 0) {
      for (let i = 1; i <= 18; i++) {
        let strokeIndex =
          scorecard.hcpHole[i - 1] == 0 ? i : scorecard.hcpHole[i - 1]
        if (strokeIndex <= totalShots) {
          shotsArray[i - 1]++
        }
      }
      totalShots -= holes
    }
  } else if (totalShots < 0) {
    while (totalShots < 0) {
      for (let i = 1; i <= 18; i++) {
        let strokeIndex =
          19 - (scorecard.hcpHole[i - 1] == 0 ? i : scorecard.hcpHole[i - 1])
        if (strokeIndex <= Math.abs(totalShots)) {
          shotsArray[i - 1]--
        }
      }
      totalShots += holes
    }
  }

  return {
    dailyHandicap: shots,
    shots: shotsArray,
  }
}

const stablefordScore = (score) => {
  switch (score) {
    case -5:
      return 7
    case -4:
      return 6
    case -3:
      return 5
    case -2:
      return 4
    case -1:
      return 3
    case 0:
      return 2
    case 1:
      return 1
    default:
      return 0
  }
}

const parseScore = (score) => {
  if (score > 0) return `+${score}`
  else if (score == 0) return "E"
  else return score
}
